/* eslint-disable max-lines */
const TRACKING_IDS = {
  alerts: {
    actionMenu: {
      addBoards: 'alerts_action-menu--add-boards',
      addMenu: 'alerts_action-menu--add-menu',
      addToDigest: 'alerts_action-menu--add-to-digest',
      addTopics: 'alerts_action-menu--add-topics',
      addWhyItMatters: 'alerts_action-menu--add-why-it-matters',
      block: 'alerts_action-menu--block',
      bulkActions: {
        select: 'alerts_action-menu--bulk-actions-select',
        selectAll: 'alerts_action-menu--bulk-actions-select-all',
        selectMenu: 'alerts_action-menu--bulk-actions-select-menu',
      },
      delete: 'alerts_action-menu--delete',
      editWhyItMatters: 'alerts_action-menu--edit-why-it-matters',
      move: {
        archived: 'alerts_action-menu--move-archived',
        deleted: 'alerts_action-menu--move-deleted',
        unprocessed: 'alerts_action-menu--move-unprocessed',
        working: 'alerts_action-menu--move-working',
      },
      moveMenu: 'alerts_action-menu--move-menu',
      newPageAlert: 'alerts_action-menu--new-page-alert',
      priority: {
        important: 'alerts_action-menu--priority-important',
        interesting: 'alerts_action-menu--priority-interesting',
      },
      priorityMenu: 'alerts_action-menu--priority-menu',
      publish: 'alerts_action-menu--publish',
      removeFromDigest: 'alerts_action-menu--remove-from-digest',
      shareToMenu: 'alerts_action-menu--share-to-menu',
      shareToSlack: 'alerts_action-menu--share-to-slack',
      unblock: 'alerts_action-menu--unblock',
      unpublish: 'alerts_action-menu--unpublish',
      copy: 'alerts_action-menu--copy',
    },
    actions: {
      addToDigest: 'alerts_actions--add-to-digest',
      delete: 'alerts_actions--delete',
      monitor: 'alerts_actions--monitor',
      publish: 'alerts_actions--publish',
      removeFromDigest: 'alerts_actions--remove-from-digest',
      unpublish: 'alerts_actions--unpublish',
      viewInDigest: 'alerts_actions--view-in-digest',
    },
    dateDivider: {
      last7days: 'alerts_date-divider--last-7-days',
      last30days: 'alerts_date-divider--last-30-days',
      last90days: 'alerts_date-divider--last-90-days',
      menu: 'alerts_date-divider--menu',
      today: 'alerts_date-divider--today',
      yesterday: 'alerts_date-divider--yesterday',
    },
    intelCard: {
      badges: {
        comments: 'alerts_intel-card-badge--comments',
        newPageAlert: 'alerts_intel-card-badge--new-page-alert',
        publishedToFeed: 'alerts_intel-card-badge--published-to-feed',
        viewInDigest: 'alerts_intel-card-badge--view-in-digest',
      },
      collapseButton: 'alerts_intel-card--collapse-button',
      collapseRepliesCountButton:
        'alerts_intel-card--collapse-replies-count-button',
      collapseRepliesThreadButton:
        'alerts_intel-card--collapse-replies-thread-button',
      expandButton: 'alerts_intel-card--expand-button',
      expandRepliesCountButton:
        'alerts_intel-card--expand-replies-count-button',
      expandRepliesThreadButton:
        'alerts_intel-card--expand-replies-thread-button',
      footer: {
        addTopics: 'alerts_intel-card-footer--add-topics',
        removePriorityTag: 'alerts_intel-card-footer--remove-priority-tag',
        removeTopic: 'alerts_intel-card-footer--remove-topic',
      },
      header: {
        sourcePermalink: 'alerts_intel-card-header--source-permalink',
      },
      relatedAlert: {
        anchorLink: 'alerts_intel-card-related-alert--anchor-link',
        removeLink: 'alerts_intel-card-related-alert--remove-link',
        text: 'alerts_intel-card-related-alert--text',
      },
      relatedBoards: {
        addBoardItem: 'alerts_intel-card-related-boards--add-board-item',
        addBoardMenu: 'alerts_intel-card-related-boards--add-board-menu',
        board: 'alerts_intel-card-related-boards--board',
        filter: 'alerts_intel-card-related-boards--filter',
        remove: 'alerts_intel-card-related-boards--remove',
        viewBoard: 'alerts_intel-card-related-boards--view-board',
      },
      title: {
        cancel: 'alerts_intel-card-title--cancel',
        edit: 'alerts_intel-card-title--edit',
        revertToOriginal: 'alerts_intel-card-title--revert-to-original',
        save: 'alerts_intel-card-title--save',
      },
      viewFullAlertInFeed: 'alerts_intel-card--view-full-alert-in-feed',
    },
    intelList: {
      errors: {
        genericError: {
          action: 'alerts_intel-list-error-action--reload-alerts',
          errorContainer: 'alerts_intel-list-error--generic-error',
        },
        invalidQuery: {
          action: 'alerts_intel-list-error-action--reset-alerts',
          errorContainer: 'alerts_intel-list-error--invalid-query',
        },
      },
    },
    leftMenu: {
      allIntel: 'alerts_left-menu--all-intel',
      archived: 'alerts_left-menu--all-archived',
      deleted: 'alerts_left-menu--all-deleted',
      important: 'alerts_left-menu--important',
      interesting: 'alerts_left-menu--interesting',
      myNewIntel: 'alerts_left-menu--my-new-intel',
      published: 'alerts_left-menu--published',
      reviewInsights: 'alerts_left-menu--reviewInsights',
      searchSavedViews: 'alerts_left-menu--search-saved-views',
      sections: {
        mySavedViews: 'alerts_left-menu--section-my-saved-views',
        smartSets: 'alerts_left-menu--section-smart-sets',
        states: 'alerts_left-menu--section-states',
      },
      selectedSavedView: 'alerts_left-menu--selected-saved-view',
      settings: 'alerts_left-menu--settings',
      unprocessed: 'alerts_left-menu--all-unprocessed',
      working: 'alerts_left-menu--all-working',
    },
    rightMenu: {
      boardGroups: 'alerts_right-menu--boardGroups',
      boards: 'alerts_right-menu--boards',
      customDateRange: {
        checked: 'alerts_right-menu--customDateRange-radio-checked',
        unchecked: 'alerts_right-menu--customDateRange-radio-unchecked',
      },
      dateRange: 'alerts_right-menu--dateRange',
      emptyUserBoardsNoticeAnchor:
        'alerts_right-menu--ny-boards-empty-notice-anchor',
      myBoards: 'alerts_right-menu--myBoards',
      priorities: 'alerts_right-menu--priorities',
      processedByMe: {
        toggle: 'alerts_right-menu--processed-by-me_toggle',
      },
      sort: 'alerts_right-menu--sort',
      sources: 'alerts_right-menu--sources',
      states: 'alerts_right-menu--states',
      topics: 'alerts_right-menu--topics',
      types: 'alerts_right-menu--types',
    },
    shareToSlackModal: {
      connect: 'alerts_share-to-slack-modal--connect',
      reconnect: 'alerts_share-to-slack-modal--reconnect',
      selectChannelDropdown:
        'alerts_share-to-slack-modal--select-channel-dropdown',
      selectChannelItemPrefix:
        'alerts_share-to-slack-modal--select-channel-item',
      selectChannelSelectedItemPrefix:
        'alerts_share-to-slack-modal--select-channel-selected-item',
      send: 'alerts_share-to-slack-modal--send',
    },
    topBar: {
      bulkActions: {
        addToDigest: 'alerts_top-bar--bulk-actions-add-to-digest',
        archive: 'alerts_top-bar--bulk-actions-archive',
        boardsDropdown: {
          apply: 'alerts_top-bar--bulk-actions-boards-dropdown-apply',
          boards: 'alerts_top-bar--bulk-actions-boards-dropdown-boards',
          button: 'alerts_top-bar--bulk-actions-boards-dropdown',
          cancel: 'alerts_top-bar--bulk-actions-boards-dropdown-cancel',
          myBoards: {
            apply:
              'alerts_top-bar--bulk-actions-boards-dropdown-myboards-apply',
            button: 'alerts_top-bar--bulk-actions-boards-dropdown-myboards',
            cancel:
              'alerts_top-bar--bulk-actions-boards-dropdown-myboards-cancel',
          },
        },
        cancel: 'alerts_top-bar--bulk-actions-cancel-button',
        cancelAction: 'alerts_top-bar--bulk-actions-cancel-action',
        confirmAction: 'alerts_top-bar--bulk-actions-confirm-action',
        delete: 'alerts_top-bar--bulk-actions-delete',
        enable: 'alerts_top-bar--bulk-actions-enable-button',
        moveToDropdown: {
          apply: 'alerts_top-bar--bulk-actions-move-to-dropdown-apply',
          button: 'alerts_top-bar--bulk-actions-move-to-dropdown',
          cancel: 'alerts_top-bar--bulk-actions-move-to-dropdown-cancel',
        },
        prioritizeDropdown: {
          apply: 'alerts_top-bar--bulk-actions-prioritize-dropdown-apply',
          button: 'alerts_top-bar--bulk-actions-prioritize-dropdown',
          cancel: 'alerts_top-bar--bulk-actions-prioritize-dropdown-cancel',
        },
        publish: 'alerts_top-bar--bulk-actions-publish',
        selectAll: 'alerts_top-bar--bulk-actions--select-all',
        selectAllCheckbox: 'alerts_top-bar--bulk-actions-select-all-checkbox',
        selectDropdown: {
          selectAll: 'alerts_top-bar--bulk-actions-select-dropdown-select-all',
          selectNone:
            'alerts_top-bar--bulk-actions-select-dropdown-select-none',
        },
        selectNone: 'alerts_top-bar--bulk-actions--select-none',
        topicsDropdown: {
          apply: 'alerts_top-bar--bulk-actions-topics-dropdown-apply',
          button: 'alerts_top-bar--bulk-actions-topics-dropdown',
          cancel: 'alerts_top-bar--bulk-actions-topics-dropdown-cancel',
        },
      },
      container: 'alerts_top-bar--container',
      filters: {
        allAlerts: 'alerts_top-bar--filters-all-alerts',
        processedBy: 'alerts_top-bar--filters-processed-by',
      },
      savedViews: {
        actionsDropdown: {
          createNewViewButton:
            'alerts_top-bar_saved-views--actions-dropdown--create-new-view-button',
          deleteButton:
            'alerts_top-bar_saved-views--actions-dropdown--delete-button',
          renameButton:
            'alerts_top-bar_saved-views--actions-dropdown--rename-button',
          splitButton:
            'alerts_top-bar_saved-views--actions-dropdown--split-button',
        },
        createNewViewButton:
          'alerts_top-bar_saved-views--create-new-view-button',
        input: {
          cancelButton: 'alerts_top-bar_saved-views--input--cancel-button',
          saveNameButton: 'alerts_top-bar_saved-views--input--save-name-button',
        },
        listOfViewsDropdownButton:
          'alerts_top-bar_saved-views--list-of-views-dropdown-button',
        resetButton: 'alerts_top-bar_saved-views--reset-button',
        savedSplitButton: 'alerts_top-bar_saved-views--saved-split-button',
        saveSplitButton: 'alerts_top-bar_saved-views--save-split-button',
        selectSavedViews: 'alerts_top-bar_saved-views--select-saved-views',
      },
      triageToogleBox: 'alerts_top-bar--triage-toggle-box',
      triageToogleOFF: 'alerts_top-bar--triage-toggle-off',
      triageToogleON: 'alerts_top-bar--triage-toggle-on',
    },
    triageActions: {
      addToImportant: 'alerts_triage-actions--tag-as-important',
      addToInteresting: 'alerts_triage-actions--tag-as-interesting',
      back: 'alerts_triage-actions--back',
      moveToArchived: 'alerts_triage-actions--move-to-archived',
      moveToDeleted: 'alerts_triage-actions--move-to-deleted',
      skip: 'alerts_triage-actions--skip',
    },
  },
  askKlue: {
    answer: {
      copyButton: 'ask-klue--answer-copy-button',
      negativeFeedbackButton: 'ask-klue--answer-negative-feedback-button',
      positiveFeedbackButton: 'ask-klue--answer-positive-feedback-button',
      showLessSourceButton: 'ask-klue--answer-show-less-source-button',
      showMoreSourceButton: 'ask-klue--answer-show-more-source-button',
      source: 'ask-klue--answer-source',
      inlineCitation: 'ask-klue--answer-inline-citation',
      tryAgainButton: 'ask-klue--try-again-button',
      suggestedQuestions: 'ask-klue--suggested-questions',
    },
    askKlueButton: 'ask-klue--ask-klue-button',
    askKlueInput: 'ask-klue--ask-klue-input',
    feedback: {
      yesButton: 'ask-klue--feedback-yes-button',
      noButton: 'ask-klue--feedback-no-button',
      skipButton: 'ask-klue--feedback-skip-button',
      submitButton: 'ask-klue--feedback-submit-button',
      dismissButton: 'ask-klue--feedback-dismiss-button',
    },
    singleClickPromptButton: 'ask-klue--single-click-prompt-button',
    wizardButton: 'ask-klue--wizard-button',
    dashboardConsumerToggle: 'ask-klue--dashboard-consumer-toggle',
    viewAs: {
      select: 'ask-klue--view-as-select',
      selectItem: 'ask-klue--view-as-select-item',
    },
  },
  callInsights: {
    competitorFilterDrop: 'call-insights_competitor-filter-drop',
    competitorFilterPrefix: 'call-insights_competitor-filter',
    dateRangeFilter: 'call-insights_date-range-filter',
    dealStageFilterDrop: 'call-insights_deal-stage-filter-drop',
    dealStageFilterPrefix: 'call-insights_deal-stage-filter',
    trackerFilterDrop: 'call-insights_tracker-filter-drop',
    trackerFilterPrefix: 'call-insights_tracker-filter',
    requestCallProvider: 'call-insights_request-call-provider',
    showLess: 'call-insights_show-less',
    showMore: 'call-insights_show-more',
    manageAliases: 'call-insights_manage-aliases',
    summarizeWidget: {
      summarizeButton: 'call-insights_summarize-widget--summarize-button',
      deleteSummaryButton:
        'call-insights_summarize-widget--delete-summary-button',
      retryButton: 'call-insights_summarize-widget--retry-button',
    },
  },
  companyPage: {
    overview: {
      footerCitation: 'company-page_overview--footer-citation',
      inlineCitation: 'company-page_overview--inline-citation',
      shareLink: 'company-page_overview--share-link',
    },
    sideNav: {
      battlecards: 'company-page_side-nav--battlecards',
      companyName: 'company-page_side-nav--company-name',
      news: 'company-page_side-nav--news',
      profile: 'company-page_side-nav--profile',
    },
    whatsNew: {
      inlineCitation: 'company-page_whats-new--inline-citation',
    },
  },
  composer: {
    battlecardView: 'composer_battlecard-view',
    cancelGeneration: 'composer-cancel-generation',
    cardNavigate: 'composer_card-navigate',
    continueEditing: 'composer_continue-editing',
    deleteProject: {
      button: 'composer_delete-project--button',
      cancel: 'composer_delete-project--cancel',
      confirm: 'composer_delete-project--confirm',
    },
    error: {
      card: {
        retry: 'composer_error-card--retry',
        supportLink: 'composer_error-card--support-link',
      },
    },
    errorSources: {
      continue: 'composer_error-sources--continue',
      cancel: 'composer_error-sources--cancel',
    },
    fileAdd: 'composer_file-add',
    fileClick: {
      errorDialog: 'composer_file-click--error-dialog',
      filesList: 'composer_file-click--files-list',
      processingDialog: 'composer_file-click--processing-dialog',
      sourcesList: 'composer_file-click--sources-list',
    },
    fileDelete: 'composer_file-delete',
    fileSelect: 'composer_file-select',
    generate: {
      projectInstructions: 'composer_generate--instructions',
      projectNavbar: 'composer_generate--navbar',
      newDraft: 'composer_generate--new-draft',
    },
    improve: {
      card: 'composer_improve--card',
    },
    newDraft: {
      projectPublishSuccessModal: 'composer_new-draft--success-modal',
      projectsNavigation: 'composer_new-draft--navigation',
    },
    processingSources: {
      continue: 'composer_processing-sources--continue',
      cancel: 'composer_processing-sources--cancel',
    },
    projectActions: 'composer_project-actions',
    projectNavigate: 'composer_project-navigate',
    projectPublish: 'composer_project-publish',
    regenerate: {
      card: 'composer_regenerate--card',
    },
    rivalSelect: 'composer_rival-select',
    saveDraft: 'composer_save-draft',
    sendToBoard: 'composer_send-to-board',
    sentiment: {
      negative: 'composer_sentiment--negative',
      positive: 'composer_sentiment--positive',
    },
    sentimentCancel: 'composer_sentiment-cancel',
    sentimentSubmit: 'composer_sentiment-submit',
    sentimentTextareaFocus: 'composer_sentiment-textarea-focus',
    settingsNavigate: 'composer_project-settings-navigate',
    sideNav: {
      collapseArrow: 'composer_side-nav--collapse-arrow',
      contentHeader: 'composer_side-nav--content-header',
    },
    subNav: {
      createButton: 'composer_sub-nav--create-button',
      draftChooser: {
        button: 'composer_sub-nav--draft-chooser-button',
      },
    },
    suggestSourcesButton: 'composer_suggest-sources-button',
    templateToggle: 'composer_template-toggle',
    websiteAdd: 'composer_website-add',
    websiteInput: 'composer_website-input',
    websiteSubmit: 'composer_website-submit',
  },
  consumer: {
    newsCard: {
      content: {
        intelMessageContent: {
          repliesCountButton: {
            collapse:
              'consumer-news-card--intel-message-content--replies-count-button--collapse',
            expand:
              'consumer-news-card--intel-message-content--replies-count-button--expand',
          },
          repliesThreadButton: {
            collapse:
              'consumer-news-card--intel-message-content--replies-thread-button--collapse',
            expand:
              'consumer-news-card--intel-message-content--replies-thread-button--expand',
          },
        },
      },
    },
  },
  dashboard: {
    curatorControls: {
      editGroups: 'dashboard-curator-controls_edit-groups',
      newBoard: 'dashboard-curator-controls_new-board',
      saveDefaults: 'dashboard-curator-controls_save-defaults',
      viewAs: 'dashboard-curator-controls_view-as',
    },
    filters: {
      groupDrop: {
        button: 'dashboard-filters_group-drop',
        itemPrefix: 'dashboard-filters_group-item',
      },
      sortDrop: {
        button: 'dashboard-filters_sort-drop',
        itemPrefix: 'dashboard-filters_sort-item',
      },
    },
    tabs: {
      boards: 'dashboard_tabs--boards',
      favorites: 'dashboard_tabs--favorites',
      news: 'dashboard_tabs--news',
    },
    news: {
      author: 'dashboard-news_author',
      board: 'dashboard-news_board',
      externalLink: 'dashboard-news_external-link',
      readMore: 'dashboard-news_read-more',
      toggleChecked: 'dashboard-news_toggle-checked',
      toggleUnchecked: 'dashboard-news_toggle-unchecked',
      wim: 'dashboard-news_why-it-matters',
    },
    rivalGroup: {
      accordion: 'dashboard-rival-group_accordion',
    },
    rivalItem: {
      battlecardsButton: 'dashboard-rival-item_battlecards-button',
      button: 'dashboard-rival-item_button',
      profileButton: 'dashboard-rival-item_profile-button',
      unreadBadge: 'dashboard-rival-item_unread-badge',
    },
    competitive: {
      card: 'dashboard-competitive-card',
      callToAction: 'dashboard-competitive-call-to-action',
      emptyState: 'dashboard-competitive-empty-state',
    },
    alerts: {
      card: 'dashboard-competitive-card',
      callToAction: 'dashboard-competitive-call-to-action',
    },
  },
  editor: {
    tags: {
      addTag: 'editor-tags-add-tag',
    },
    visibilityGroups: {
      done: 'editor-visibility-groups-done',
    },
  },
  filterDropTab: 'filter-drop-tab',
  global: {
    appNavBar: {
      alerts: 'app-nav-bar_alerts',
      boards: 'app-nav-bar_boards',
      competitiveAnalytics: 'app-nav-bar_competitive-analytics',
      composer: 'app-nav-bar_composer',
      consumerUsage: 'app-nav-bar_consumer-usage',
      curatorActivity: 'app-nav-bar_curator-activity',
      digest: 'app-nav-bar_digest',
      discover: 'app-nav-bar_discover',
      feed: 'app-nav-bar_feed',
      globalSearch: {
        button: 'app-nav-bar_global-search-button',
        context: {
          alerts: 'app-nav-bar_global-search-context-alerts',
          cards: 'app-nav-bar_global-search-context-cards',
        },
        input: 'app-nav-bar_global-search-input',
        suggestions: {
          battlecard: {
            profile: 'app-nav-bar_global-search-suggestions-battlecard-profile',
            battlecardTitle:
              'app-nav-bar_global-search-suggestions-battlecard-title',
          },
        },
      },
      home: 'app-nav-bar_home',
      insights: 'app-nav-bar_insights',
      integrations: 'app-nav-bar_integrations',
      measure: 'app-nav-bar_measure',
      monitors: 'app-nav-bar_monitors',
      quickSwitcher: 'app-nav-bar_quick-switcher',
      research: 'app-nav-bar_research',
      responsiveMenu: 'app-nav-bar_responsive-menu',
      salesCalls: 'app-nav-bar_sales-calls',
      share: 'app-nav-bar_share',
      submitIntel: 'app-nav-bar_submit-intel',
      threatAnalysis: 'app-nav-threat-analysis',
      userMenu: 'app-nav-bar_user-menu',
    },
    card: {
      actionMenu: {
        button: 'card-action-button',
        copyEmbed: 'card-action-menu_copy-embed',
        copyLink: 'card-action-menu_copy-link',
        duplicateCard: 'card-action-menu_duplicate-card',
        moveCard: 'card-action-menu_move-card',
        edit: 'card-action-menu_edit',
        openCard: 'card-action-menu_open-card',
        sources: 'card-action-menu_sources',
        assignToBattlecards: 'card-action-menu_assign-to-battlecard',
        cardPermissions: 'card-action-menu_card-permissions',
      },
      moveCard: {
        cancelButton: 'move-card_cancel-button',
        boardSelect: 'move-card_board_select',
        boardSelectItem: 'move-card_board_select-item',
        laneSelect: 'move-card_lane_select',
        laneSelectItem: 'move-card_lane_select-item',
        submitButton: 'move-card_submit-button',
      },
      author: 'card-author',
      collapseButton: {
        showAll: 'card-collapse-button-show-all',
        showFewer: 'card-collapse-button-show-fewer',
      },
      sentiment: {
        negative: 'card-sentiment_negative',
        positive: 'card-sentiment_positive',
      },
      shareFeedback: {
        cancel: 'card-sentiment_share-feedback_cancel',
        cancelModal: 'card-sentiment_share-feedback_modal_cancel',
        openModal: 'card-sentiment_share-feedback_modal',
        send: 'card-sentiment_share-feedback_send',
        sendModal: 'card-sentiment_share-feedback_modal_send',
        share: 'card-sentiment_share-feedback',
      },
      title: 'card-title',
      toolbar: {
        copyEmbed: 'card-toolbar_copy-embed',
        copyLink: 'card-toolbar_copy-link',
        duplicateCard: 'card-toolbar_duplicate-card',
        openCard: 'card-toolbar_open-card',
        sources: 'card-toolbar_sources',
      },
    },
    cardViewer: {
      backButton: 'card-viewer_back-button',
      breadcrumbs: {
        author: 'card-viewer_breadcrumbs-author',
        board: 'card-viewer_breadcrumbs-board',
        lane: 'card-vewer_breadcrumbs-lane',
      },
      closeButton: 'card-viewer_close-button',
      editButton: 'card-viewer_edit-button',
    },
    previewBanner: {
      dropdown: 'preview-banner-group-dropdown',
      reset: 'preview-banner-group-reset',
    },
    profileCard: {
      battlecard: 'profile-card-battlecard',
      viewAllCards: 'profile-card-view-all-cards',
    },
    submitIntel: {
      cancelButton: 'submit-intel_cancel-button',
      select: 'submit-intel_select',
      selectItem: 'submit-intel_select-item',
      submitButton: 'submit-intel_submit-button',
    },
    summary: {
      cancel: 'alerts_summary--cancel',
      delete: 'alerts_summary--delete',
      edit: 'alerts_summary--edit',
      feedback: 'alerts_summary--feedback',
      menu: 'alerts_summary--menu',
      options: {
        format: {
          bullets: 'alerts_summary--options-format-bullets',
          paragraphs: 'alerts_summary--options-format-paragraphs',
        },
        length: {
          concise: 'alerts_summary--options-length-concise',
          lengthy: 'alerts_summary--options-length-lengthy',
        },
        tone: {
          friendly: 'alerts_summary--options-tone-friendly',
          professional: 'alerts_summary--options-tone-professional',
        },
      },
      retry: 'alerts_summary--retry',
      save: 'alerts_summary--save',
      summarize: 'alerts_summary--summarize',
      tryAgain: 'alerts_summary--error-retry',
    },
    supportedRivalCard: {
      viewProfile: 'supportedRival-card-view-profile',
    },
  },
  knowledgeHub: {
    checklist: {
      callToAction: 'knowledge-hub_checklist--call-to-action',
      link: 'knowledge-hub_checklist--link',
    },
    variables: {
      doneButton: 'knowledge-hub_variables_done-button',
      removeVariableButton: 'knowledge-hub_variables_remove-variable-button',
    },
    files: {
      deleteFile: 'knowledge-hub_files_delete-file',
      doneButton: 'knowledge-hub_files_done-button',
      downloadFile: 'knowledge-hub_files_download-file',
    },
    products: {
      doneButton: 'knowledge-hub_products_done-button',
      list: {
        addProductButton: 'knowledge-hub_products_list_add-product-button',
        item: {
          name: 'knowledge-hub_products_list_item_name',
          nameInput: 'knowledge-hub_products_list_item_name-input',
        },
      },
    },
    done: {
      link: 'knowledge-hub_done--back-to-dashboard-link',
    },
    widget: {
      button: 'knowledge-hub_widget--button',
    },
    error: {
      reloadButton: 'knowledge-hub_error--reload-button',
      contactSupportLink: 'knowledge-hub_error--contact-support-link',
    },
  },
  integrations: {
    accessTokens: {
      backButtonFromCreate:
        'integrations_access-tokens_back-button-from-create',
      backButtonFromRevoke:
        'integrations_access-tokens_back-button-from-revoke',
      backButtonFromView: 'integrations_access-tokens_back-button-from-view',
      copyTokenButton: 'integrations_access-tokens_copy-token-button',
      openCreateView: 'integrations_access-tokens_open-create-view',
      openRevokeView: 'integrations_access-tokens_open-revoke-view',
      revokeTokenButton: 'integrations_access-tokens_revoke-token-button',
      saveTokenButton: 'integrations_access-tokens_save-token-button',
      tokenDescriptionInput:
        'integrations_access-tokens_token-description-input',
    },
    closeUninstallDialog: (slug: string) =>
      `integrations_close-uninstall-dialog--${slug}`,
    configureButton: (slug: string) => `integrations_configure--${slug}`,
    gongCallInsights: {
      reloadButton: 'integrations_gong-call-insights_reload-button',
    },
    helpUrl: (slug: string) => `integrations_help-url--${slug}`,
    menuButton: (slug: string) => `integrations_menu-button--${slug}`,
    openUninstallDialog: (slug: string) =>
      `integrations_open-uninstall-dialog--${slug}`,
    primaryButton: (slug: string) => `integrations_get-button--${slug}`,
    sections: {
      api: 'api_section',
      featured: 'featured_section',
    },
    statusButton: (slug: string) => `integrations_status--${slug}`,
    toggleButton: (slug: string) => `integrations_toggle--${slug}`,
    uninstallButton: (slug: string) => `integrations_uninstall--${slug}`,
    waitlistButton: (slug: string) => `integrations_waitlist--${slug}`,
  },
  monitors: {
    card: {
      collapse: 'monitors_card--collapse',
      expand: 'monitors_card--expand',
      externalLink: 'monitors_card--external-link',
      highlights: {
        left: {
          select: 'monitors_card--highlights-left-select',
          selectItem: 'monitors_card--highlights-left-select-item',
        },
        right: {
          select: 'monitors_card--highlights-right-select',
          selectItem: 'monitors_card--highlights-right-select-item',
        },
      },
      imageTab: {
        button: 'monitors_card--image-tab-button',
      },
      textTab: {
        button: 'monitors_card--text-tab-button',
        disableChangesOnly: 'monitors_card--text-tab-disable-changes-only',
        enableChangesOnly: 'monitors_card--text-tab-enable-changes-only',
      },
      relatedBoards: {
        addBoardItem: 'monitors-related-boards--add-board-item',
        addBoardMenu: 'monitors-related-boards--add-board-menu',
        board: 'monitors-related-boards--board',
        filter: 'monitors-related-boards--filter',
        remove: 'monitors-related-boards--remove',
        viewBoard: 'monitors-related-boards--view-board',
      },
      titleCollapse: 'monitors_card--title-collapse',
      titleExpand: 'monitors_card--title-expand',
    },
    pagination: {
      container: 'monitors_pagination--container',
    },
    topBar: {
      clearFiltersButton: 'monitors_top-bar--clear-filters-button',
      filters: {
        allBoards: {
          boardGroups: {
            tab: 'monitors_top-bar--filters-board-groups-tab',
          },
          boards: {
            prefix: 'monitors_top-bar--filters-boards-board',
            tab: 'monitors_top-bar--filters-boards-tab',
          },
          button: 'monitors_top-bar--filters-all-boards-button',
          myBoards: {
            tab: 'monitors_top-bar--filters-my-boards-tab',
          },
          search: 'monitors_top-bar--filters-all-boards-search',
          selectAll: 'monitors_top-bar--filters-all-boards-select-all',
          sort: 'monitors_top-bar--filters-all-boards-sort',
        },
        monitorSort: {
          button: 'monitors_top-bar--filters-sort-button',
          prefix: 'monitors_top-bar--filters-sort-item',
          sort: 'monitors_top-bar--filters-sort-sort',
        },
      },
      showAllButton: 'monitors_top-bar--show-all-button',
      showLessButton: 'monitors_top-bar--show-less-button',
    },
  },
  profile: {
    battlecard: {
      layoutDrop: {
        button: 'profile_battlecard-layout-drop--button',
        option: 'profile_battlecard-layout-drop--radio--option',
        radioGroup: 'profile_battlecard-layout-drop--radio-group',
      },
      reviewer: 'profile_battlecard--reviewer',
      markAsReviewed: 'profile_battlecard--mark-as-reviewed',
      stacker: {
        cardItem: 'profile_battlecard--stacker_cardItem',
      },
      edit: {
        save: 'profile_battlecard--edit-save',
        layout: {
          radioGroup: 'profile_battlecard--edit-layout-radio-group',
        },
      },
    },
    boardActionMenu: {
      bam: 'profile_board-action-menu--bam',
      reorderCards: 'profile_board-action-menu--reorder-cards',
      manageAlerts: 'profile_board-action-menu--manage-alerts',
      manageBattleCards: 'profile_board-action-menu--manage-battle-cards',
      manageLanes: 'profile_board-action-menu--manage-lanes',
      manageMonitors: 'profile_board-action-menu--manage-monitors',
      bulkActions: 'profile_board-action-menu--bulk-action',
      settings: 'profile_board-action-menu--settings',
      clone: 'profile_board-action-menu--clone',
    },
    bulkedit: {
      selectAllBoard: 'profile_bulkedit--select-all-board',
      selectAllLane: 'profile_bulkedit--select-all-lane',
    },
    lane: {
      addCard: 'profile_lane--add-card',
      addLane: 'profile_lane--add-lane',
    },
    settings: {
      curratorAssignmentDrop: 'profile_settings--currator-assignment-drop',
      groupAssignmentDrop: 'profile_settings--group-assignment-drop',
    },
    sideNav: {
      battlecardDrop: {
        button: 'profile_side-nav-battlecard-drop--button',
        item: 'profile_side-nav-battlecard-drop--item',
      },
      collapseArrow: 'profile_side-nav--collapse-arrow',
      contentHeader: 'profile_side-nav--content-header',
      key: 'profile_side-nav',
    },
    subNav: {
      allCardsButton: 'profile_sub-nav-all-cards-button',
      battlecardDrop: {
        button: 'profile_sub-nav-battlecard-drop--button',
        item: 'profile_sub-nav-battlecard-drop--item',
      },
      boardButton: 'profile_sub-nav-board-button',
      filtersDrop: {
        button: 'profile_sub-nav-filters-drop--button',
        closeButton: 'profile_sub-nav-filters-drop--close-button',
        lanesTab: {
          button: 'profile_sub-nav-filters-drop--lanes-tab',
          filterItem: 'profile_sub-nav-filters-drop--lanes-tab_filterItem',
          selectAll: 'profile_sub-nav-filters-drop--lanes-tab_selectAll',
          sort: 'profile_sub-nav-filters-drop--lanes-tab_sort',
        },
        savedViews: {
          cancelViewName: 'profile_my-saved-view--cancel_view_name',
          delete: 'profile_my-saved-view--delete',
          save: 'profile_my-saved-view--save',
          saveViewName: 'profile_my-saved-view--save_view_name',
          select: 'profile_my-saved-view--select_open',
          selectItem: 'profile_my-saved-view--selected_item',
        },
        tagsTab: {
          button: 'profile_sub-nav-filters-drop--tags-tab',
          filterItem: 'profile_sub-nav-filters-drop--tags-tab_filterItem',
          search: 'profile_sub-nav-filters-drop--tags-tab_search',
          selectAll: 'profile_sub-nav-filters-drop--tags-tab_selectAll',
          sort: 'profile_sub-nav-filters-drop--tags-tab_sort',
        },
      },
      newsButton: 'profile_sub-nav-news-button',
      vitalsDrop: {
        button: 'profile_sub-nav-vitals-drop--button',
      },
      viewAsDrop: {
        button: 'profile_sub-nav-view-as-drop--button',
      },
    },
  },
  reports: {
    competitiveAnalytics: {
      importInitialDataButton:
        'reports--competitive-analytics-import-initial-data',
      updateDataButton: 'reports--competitive-analytics-update-data-button',
      uploadDialog: {
        errorCloseButton:
          'reports--competitive-analytics-upload-dialog-error-close-button',
        exportFailedRowsButton:
          'reports--competitive-analytics-upload-dialog-export-failed-rows-button',
        successCloseButton:
          'reports--competitive-analytics-upload-dialog-success-close-button',
      },
    },
    downloadData: 'reports--download-data',
    navigation: {
      'competitive-analytics': {
        'data-hygiene':
          'reports_navigation--competitive-analytics-data-hygiene',
        impact: 'reports_navigation--competitive-analytics-impact',
        overview: 'reports_navigation--competitive-analytics-overview',
        parent: 'reports_navigation--competitive-analytics',
        'rep-performance':
          'reports_navigation--competitive-analytics-rep-performance',
        'threat-analysis':
          'reports_navigation--competitive-analytics-threat-analysis',
      },
      'consumer-usage': {
        leaderboards: 'reports_navigation--consumer-usage-leaderboards',
        overview: 'reports_navigation--consumer-usage-overview',
        parent: 'reports_navigation--consumer-usage',
      },
      curator: 'reports_navigation--curator',
      individual: 'reports_navigation--individual',
    },
    toggleFilters: 'reports--toggle-filters',
  },
  research: {
    note: {
      relatedBoards: {
        addBoardItem: 'research_note-related-boards--add-board-item',
        addBoardMenu: 'research_note-related-boards--add-board-menu',
        board: 'research_note-related-boards--board',
        filter: 'research_note-related-boards--filter',
        remove: 'research_note-related-boards--remove',
        viewBoard: 'research_note-related-boards--view-board',
      },
    },
    sideNav: {
      collapseArrow: 'research_side-nav--collapse-arrow',
      contentHeader: 'research_side-nav--content-header',
      key: 'research_side-nav',
    },
  },
  reviewInsights: {
    filters: {
      customDate: 'review-insights-date-filter-custom',
      date: 'review-insights-date-filter',
      maxRating: 'review-insights-filters-max-rating',
      minRating: 'review-insights-filters-min-rating',
      reset: 'review-insights-filters-reset',
      reviewsFilter: {
        allReviews: 'review-insights-filters-reviews-filter-all-reviews',
        bookmarkedReviews:
          'review-insights-filters-reviews-filter-bookmarked-reviews',
        button: 'review-insights-filters-reviews-filter-button',
        radioGroup: 'review-insights-filters-reviews-filter-radio-group',
      },
      reviewTopic: 'review-insights-filters-review-topic',
      sentimentFilter: {
        all: 'review-insights-filters-sentiment-filter-all',
        button: 'review-insights-filters-sentiment-filter-button',
        negative: 'review-insights-filters-sentiment-filter-negative',
        positive: 'review-insights-filters-sentiment-filter-positive',
      },
      source: {
        filterItem: 'review-insights-source-filter',
        selectAll: 'review-insights-source-filter-select-all',
        sort: 'review-insights-source-filter-sort',
      },
      sourceType: {
        all: 'review-insights-filters-source-type-all',
        button: 'review-insights-filters-source-type-button',
        review: 'review-insights-filters-source-type-review',
        winLoss: 'review-insights-filters-source-type-win-loss',
      },
      starRating: 'review-insights-filters-star-rating',
    },
    header: {
      actionMenu: {
        pin: 'review-insights_header-action-menu-pin',
        unpin: 'review-insights_header-action-menu-unpin',
      },
      itemPrefix: 'review-insights_header--company-item',
      requestCompetitorButton:
        'review-insights_header--request-competitor-button',
      selectedItemPrefix: 'review-insights_header--company-selected',
      sourceLink: 'review-insights_header--source-link',
      title: 'review-insights_header--title',
    },
    insights: {
      createCardButton: 'review-insights_insights--create-card-button',
      createCardSubmitButton: 'review-insights_insights--create-card-submit',
      feedback: {
        feedbackButton: 'review-insights_feedback--feedback-button',
        negativeButton: 'review-insights_feedback--negative-button',
        positiveButton: 'review-insights_feedback--positive-button',
      },
      showMoreButton: 'review-insights_insights--show-more-button',
      sourceButton: 'review-insights_insights--source-button',
    },
    sidebar: {
      breadcrumb: {
        button: 'review-insights_sidebar-breadcrumbs--button',
        item: 'review-insights_sidebar-breadcrumbs--item',
      },
      header: {
        button: 'review-insights_sidebar-header--button',
        title: 'review-insights_sidebar-header--title',
      },
      item: 'review-insights_sidebar--item',
      productItem: 'review-insights_sidebar--product-item',
      reviewCount: 'review-insights_sidebar--review-count',
      searchInput: 'review-insights_sidebar--search-input',
    },
    summary: {
      actionMenu: {
        button: 'review-insights_summary-action-menu--button',
        copyText: 'review-insights_summary-action-menu--copy-text',
        createCard: 'review-insights_summary-action-menu--create-card',
      },
      feedback: 'review-insights_summary--feedback',
      reload: 'review-insights_summary--reload',
      settings: 'review-insights_summary--settings',
    },
    tabs: {
      insights: 'review-insights_tabs--insights',
      reviews: 'review-insights_tabs--reviews',
    },
    themes: {
      actionMenu: {
        button: 'review-insights_themes-action-menu--button',
        hide: 'review-insights_themes-action-menu--hide',
        unhide: 'review-insights_themes-action-menu--unhide',
      },
      allButton: 'review-insights_themes--all-button',
      aspectFilter: 'review-insights_themes--aspect-filter',
      negativeButton: 'review-insights_themes--negative-button',
      positiveButton: 'review-insights_themes--positive-button',
      sortFilterButton: 'review-insights_themes--sort-filter-button',
      title: 'review-insights_themes--title',
    },
    topTopics: {
      item: 'review-insights_top-topics--item',
    },
    createCardModal: {
      titleItemButton: 'review-insights_create-card--title-item-button',
      cancelButton: 'review-insights_create-card--cancel-button',
      saveButton: 'review-insights_create-card--save-button',
      sendToBoardButton: 'review-insights_create-card--send-to-board-button',
      copyUrlButton: 'review-insights_create-card--copy-url-button',
      nextStepButton: 'review-insights_create-card--next-step-button',
      previousStepButton: 'review-insights_create-card--previous-step-button',
      deleteInsightCardButton:
        'review-insights_create-card--delete-insight-card-button',
      cardEditor: {
        title: 'review-insights_create-card--card-editor-title',
        content: 'review-insights_create-card--card-editor-content',
      },
      form: {
        competitor: 'review-insights_create-card--form-competitor',
        cardPermissions: 'review-insights_create-card--form-card-permissions',
        visibilityGroups: 'review-insights_create-card--form-visibility-groups',
        lane: 'review-insights_create-card--form-lane',
        hasOverviewCard: 'review-insights_create-card--form-has-overview-card',
      },
    },
  },
  search: {
    appliedFilters: {
      clearButton: 'search_applied-filters--clear-button',
      overflowButton: 'search_applied-filters--overflow-button',
      removeButton: 'search_applied-filters--remove-button',
    },
    cards: {
      breadcrumb: {
        laneLink: 'search_cards-breadcrumb--lane-link',
        profileLink: 'search_cards-breadcrumb--profile-link',
      },
      empty: {
        feedback: 'search_cards-empty--feedback',
        feedbackInput: 'search_cards-empty--feedback-input',
        cancel: 'search_cards-empty--feedback-cancel',
        positiveSentiment: 'search_cards-empty--positive-sentiment',
        negativeSentiment: 'search_cards-empty--negative-sentiment',
      },
    },
    sidebar: {
      boards: {
        filterItem: 'search_sidebar--boards_filter-item',
        search: 'search_sidebar--boards_search',
        selectAll: 'search_sidebar--boards_select-all',
        sort: 'search_sidebar--boards_sort',
      },
      lastUpdated: {
        filterItem: 'search_sidebar--last-updated_filter-item',
      },
      tags: {
        filterItem: 'search_sidebar--tags_filter-item',
        search: 'search_sidebar--tags_search',
        selectAll: 'search_sidebar--tags_select-all',
        sort: 'search_sidebar--tags_sort',
      },
      toggleCollapse: 'search_sidebar--toggle-collapse',
    },
  },
  settings: {
    companyProfile: {
      getStartedButton: 'settings_company-profile--get-started-button',
      products: {
        next: 'settings_company-profile--products-next',
        stepLabels: {
          products: 'settings_company-profile--products-step-label-products',
          useCases: 'settings_company-profile--products-step-label-use-cases',
        },
        table: {
          addRowButton:
            'settings_company-profile--products-table--add-row-button',
          productRow: {
            nameInput:
              'settings_company-profile--products-table--product-row--name-input',
            actionMenu:
              'settings_company-profile--products-table--product-row--action-menu',
            deleteAction:
              'settings_company-profile--products-table--product-row--delete-action',
          },
        },
      },
      useCases: {
        back: 'settings_company-profile--use-cases-back',
        next: 'settings_company-profile--use-cases-next',
        table: {
          addRowButton: 'settings_company-profile--use-cases-table--add-row',
          useCaseRow: {
            nameInput:
              'settings_company-profile--use-cases-table--use-case-row--name-input',
            actionMenu:
              'settings_company-profile--use-cases-table--use-case-row--action-menu',
            deleteAction:
              'settings_company-profile--use-cases-table--use-case-row--delete-action',
            positioningInput:
              'settings_company-profile--use-cases-table--use-case-row--positioning-input',
            productSelect:
              'settings_company-profile--use-cases-table--use-case-row--product-select',
            rivalsSelect:
              'settings_company-profile--use-cases-table--use-case-row--rivals-select',
          },
        },
      },
      error: {
        contactSupportLink: 'settings_company-profile--error-contact-support',
        reloadButton: 'settings_company-profile--error-reload-button',
      },
    },
    sideMenu: {
      alertsSettings: 'settings_side-menu--alerts-settings',
      companyProfile: 'settings_side-menu--company-profile',
      curatorAssignments: 'settings_side-menu--curator-assignments',
      intelDigest: 'settings_side-menu--intel-digest',
      loginDomains: 'settings_side-menu--login-domains',
      monitorsSettings: 'settings_side-menu--monitors-settings',
      tags: 'settings_side-menu--tags',
    },
  },
  winLoss: {
    aiSummary: {
      actions: {
        copyText: 'winloss_summary--copy_text',
        kebabMenu: 'winloss_summary--action_menu',
        openCreateCardModal: 'winloss_summary--create_card',
      },
    },
    content: 'winloss_left-menu--content',
    createCardModal: {
      boardLaneSelector: 'winloss_create_card--select_lane',
      boardSelector: 'winloss_create_card--select_competitor',
      cancelButton: 'winloss_create_card--cancel_button',
    },
    decisionThemes: 'winloss_left-menu--themes',
    decisionThemesReports: {
      businessDrivers: 'winloss_themes--business-drivers',
      influencers: 'winloss_themes--influencers',
      lossReasons: 'winloss_themes--loss-reasons',
      pricingNegatives: 'winloss_themes--pricing_negatives',
      pricingPositives: 'winloss_themes--pricing_positives',
      productNegatives: 'winloss_themes--product_negatives',
      productPositives: 'winloss_themes--product_positives',
      salesNegatives: 'winloss_themes--sales_negatives',
      salesPositives: 'winloss_themes--sales_positives',
      selectionCriteria: 'winloss_themes--selection-criteria',
      winReasons: 'winloss_themes--win-reasons',
    },
    featuredQuote: 'winloss_program-list--quote',
    featuredQuoteList: 'winloss_program-list--quote-view-all',
    filterBar: {
      clearFilters: 'winloss_filter-bar--cleared',
    },
    globalNav: 'app-nav-bar_win-loss',
    highlightItems: {
      kebabMenu: 'winloss_highlights--action_menu',
      kebabMenuCopyOption: 'winloss_highlights--copy_text',
      kebabMenuSendToKlueOption: 'winloss_highlights--create_card',
    },
    highlightsLayoutToggle: 'winloss_highlights--list_toggle',
    interviewCard: 'winloss_explorer--interview',
    interviews: 'winloss_left-menu--interviews',
    interviewDecisionReasons: {
      thumbsDown: 'winloss_outcome-reasons--thumbs_down',
      thumbsUp: 'winloss_outcome-reasons--thumbs_up',
    },
    interviewSummary: {
      collapse: 'winloss_interview-summary--meta_collapse',
      downloadAsPdfButton: 'winloss_interview--download_pdf',
      expand: 'winloss_interview-summary--meta_expand',
    },
    outreach: 'winloss_left-menu--outreach',
    opportunities: 'winloss_left-menu--opportunities',
    promoPage: {
      takeTheTourButton: 'winloss_promo-page--take-tour',
    },
    analytics: 'winloss_left-menu--analytics',
    threatAnalysis: 'winloss_left-menu--threat-analysis',
    dataHygiene: 'winloss_left-menu--data-hygiene',
    clickableOpportunityRow: 'winloss_opportunities--list-item',
    overview: 'winloss_left-menu--overview',
    programs: 'winloss_left-menu--programs',
    program: 'winloss_program-list--program',
    programDashboard: {
      highlight: 'winloss--highlight-item',
      interviews: 'winloss_program--interviews',
      interviewsList: 'winloss_program--interviews-view-all',
      quotesList: 'winloss_program--quotes-view-all',
      themesList: 'winloss_program--themes-view-all',
    },
    programSurveys: 'winloss_left-menu--surveys',
    programLossAnalysis: 'winloss_left-menu--loss-analysis',
    programOtherAnalysis: 'winloss_left-menu--other-analysis',
    programChurnAnalysis: 'winloss_left-menu--churn-analysis',
    programWinAnalysis: 'winloss_left-menu--win-analysis',
    recentInterview: 'winloss_program-list--interview',
    recentInterviewList: 'winloss_program-list--interview-view-all',
    recentSurvey: 'winloss_program-list--survey',
    recentSurveyList: 'winloss_program-list--survey-view-all',
    reports: 'winloss_left-menu--reports',
    surveyDetails: {
      summary: {
        collapse: 'winloss_interview-summary--meta_collapse',
        expand: 'winloss_interview-summary--meta_expand',
      },
    },
    surveyItems: {
      kebabMenu: 'winloss_surveys--action_menu',
      kebabMenuOption: 'winloss_surveys--go-to-results-dashboard',
    },
    surveyOutreach: {
      sendOutreachButton: 'winloss_opportunities-detail--send-button',
      dismissOutreachButton: 'winloss_opportunities-detail--dismiss-button',
      undoDismissOutreachButton: 'winloss_opportunities-detail--undo-button',
      viewSurveyForm: 'winloss_opportunities-detail--view-survey',
      surveyFormRespondentEmail:
        'winloss_opportunities-detail--respondent-email',
      surveyFormCopyUrl: 'winloss_opportunities-detail--copy-survey-url',
      surveyFormCopyRespondentEmail:
        'winloss_opportunities-detail--copy-respondent-email',
      surveyFormCopyOwnerEmail:
        'winloss_opportunities-detail--copy-owner-email',
      viewSurveyResponses:
        'winloss_opportunities-detail--view-survey-responses',
      surveyFormOwnerEmail: 'winloss_opportunities-detail--owner-email',
    },
    dealOutreach: {
      selectContact: 'winloss_confirmation--select-contact',
      sendSurveryButton: 'winloss_confirmation--submit-button',
      dismissSendSurveyButton: 'winloss_confirmation--dismiss-button',
    },
    surveys: 'winloss_left-menu--survey-promo',
    winLossNav: 'winloss_left-menu--win-loss',
    publicPages: {
      socialMediaIcons: {
        x: 'winloss_confirmation--social-twitter',
        facebook: 'winloss_confirmation--social-facebook',
        blog: 'winloss_confirmation--social-blog',
        wellfound: 'winloss_confirmation--social-wellfound',
        linkedIn: 'winloss_confirmation--social-linkedin',
      },
      legal: {
        acceptableUse: 'winloss_confirmation--acceptable-use',
        privacy: 'winloss_confirmation--privacy',
        terms: 'winloss_confirmation--terms',
      },
    },
  },
};

export default TRACKING_IDS;

import {
  tokens,
  PhosphorIcon,
  Box,
  Heading,
  Button,
  Text,
  Modal,
  useNotifier,
  NotifierType,
} from '@kluein/klue-ui';
import { useTranslation } from 'react-i18next';

import CardBattlecardItem from './CardBattlecardItem';

import { useCardBattlecards } from '../../hooks/use-card-battlecards';

import type { BattlecardsType } from 'api/api.types';

type KlueCardAssignToBattlcardModalProps = {
  id: number;
  profileId?: number;
  onClose: () => void;
};

const KlueCardAssignToBattlcardModal = ({
  id,
  profileId,
  onClose,
}: KlueCardAssignToBattlcardModalProps) => {
  const { t } = useTranslation();
  const { notify } = useNotifier();
  const { battlecards, checked, toggleChecked, counts, save, saving } =
    useCardBattlecards({
      id,
      profileId,
    });
  const handleOnClose = () => {
    onClose();
  };
  const handleOnSave = async () => {
    try {
      await save();
      notify({
        type: NotifierType.SUCCESS,
        message: t('Card:actions.assignToBattlecards.success'),
        icon: <PhosphorIcon.Check />,
      });
      onClose();
    } catch (error) {
      notify({
        type: NotifierType.ERROR,
        message: t('Card:actions.assignToBattlecards.error'),
        icon: <PhosphorIcon.WarningCircle />,
      });
    }
  };

  return (
    <Modal
      showCloseButton={false}
      width="xxxlarge"
      onClose={handleOnClose}
      background={tokens.color.neutral.white.main}
      responsive
    >
      <Modal.Header background={tokens.color.primary.main}>
        <Box direction="row" gap="small" align="center">
          <PhosphorIcon.Star />
          <Heading
            size="medium"
            margin="none"
            level="2"
            color={tokens.color.primary.contrastmain}
          >
            {t('Card:header.menu.assignToBattlecards')}
          </Heading>
        </Box>
        <Button
          variant="plain"
          onClick={handleOnClose}
          icon={<PhosphorIcon.X color={tokens.color.primary.contrastmain} />}
        />
      </Modal.Header>
      <Modal.Content>
        <Box
          overflow="auto"
          style={saving ? { pointerEvents: 'none', opacity: 0.37 } : {}}
        >
          {battlecards.map(
            (battlecard: Partial<BattlecardsType> & { id: number }, index) => (
              <CardBattlecardItem
                key={battlecard.id}
                battlecard={battlecard}
                checked={checked.get(battlecard.id) ?? false}
                onToggleChecked={() => toggleChecked(battlecard.id)}
                isDefault={index === 0}
                cardCounts={counts.get(battlecard.id) ?? 0}
              />
            ),
          )}
          {!battlecards.length && (
            <Text size="small" color={tokens.color.primitives.grey[800]}>
              {t('Battlecard:manage.order.noBattlecards')}
            </Text>
          )}
        </Box>
        <Box
          direction="row"
          justify="end"
          margin={{ vertical: 'medium' }}
          gap="small"
        >
          <Button
            variant="flat"
            onClick={handleOnClose}
            disabled={saving}
            label={t('Common:actions.cancel')}
          />
          {battlecards.length ? (
            <Button
              variant="flat-outlined"
              onClick={handleOnSave}
              label={
                saving ? t('Common:actions.saving') : t('Common:actions.save')
              }
              disabled={saving}
            />
          ) : null}
        </Box>
      </Modal.Content>
    </Modal>
  );
};

export default KlueCardAssignToBattlcardModal;

/* eslint-disable max-lines */
import { KlueEditorContent } from '@kluein/content-editor';
import {
  Box,
  Button,
  Modal,
  TextInput,
  tokens,
  useResponsive,
  useNotifier,
  NotifierType,
  PhosphorIcon,
} from '@kluein/klue-ui';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAuth from 'contexts/auth/useAuth';
import TEST_IDS from 'test-ids';

import {
  areCardEditorPropsEqual,
  validateEditorContent,
} from './CardEditor.utils';
import CardEditorFailedLoading from './CardEditorFailedLoading';
import CardEditorLoading from './CardEditorLoading';
import { useCardEditor } from './hooks/useCardEditor';
import { useTextInputAutofocus } from './hooks/useTextInputAutofocus';
import { CardEditorCardInfo } from './partials/CardEditorInfo';
import CardEditorTags from './partials/CardEditorTags';
import { CardEditorToolbar } from './partials/CardEditorToolbar';

import { KlueCardVisibilityGroups } from '../klueCard/partials';

import type { CardType } from 'api/api.types';
import type { GetCardPermissionCardsDataResult } from 'pages/profile/partials/sideAction/bulkEdit/cardPermissions/CardPermissions.utils';

export type CardEditorProps = {
  card?: CardType;
  profileId?: number;
  isLoading?: boolean;
  failedLoading?: boolean;
  onClose: (didSave?: boolean) => void;
};

const TEMP_PLACEHOLDER_TEXT = 'Create your content here';

const CardEditor: React.FC<CardEditorProps> = ({
  card,
  profileId,
  isLoading,
  failedLoading,
  onClose,
}) => {
  const { t } = useTranslation(['Card']);
  const { isCardTitleOptional } = useAuth();
  const { notify } = useNotifier();
  const [fullScreen, setFullScreen] = useState(false);
  const [titleDirty, setTitleDirty] = useState(isCardTitleOptional);
  const editorContainerRef = useRef<HTMLDivElement>(null);
  const { setTextInputRef } = useTextInputAutofocus({ card });

  const { minWidth } = useResponsive();

  const width = !minWidth.small ? 'unset' : tokens.breakpoint.small;

  const handleContentRestore = useCallback(() => {
    notify({
      type: NotifierType.INFO,
      message: t('Card:editor.localRestore'),
      duration: 3,
    });
  }, [notify, t]);

  const {
    editor,
    title,
    updateTitle,
    saveCard,
    isSaving,
    isDraft,
    isDirty,
    allAccess,
    visibilityGroups,
    handleUpdateVisibilityGroups,
    cardPermissionsDirty,
    tags,
    addTag,
    removeTag,
    tagsDirty,
    clearBackup,
  } = useCardEditor({
    initialCard: card,
    profileId,
    editorContainerRef,
    onContentRestore: handleContentRestore,
  });

  const handleTitleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      updateTitle(event.target.value);
      setTitleDirty(true);
    },
    [updateTitle],
  );

  const handleTitleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event?.key === 'Enter' || event?.key === 'Tab') {
        event?.preventDefault();
        editor?.commands.focus('end');
      }
    },
    [editor],
  );

  const handleSave = useCallback(async () => {
    if (!isCardTitleOptional && !title) {
      return notify({
        type: NotifierType.ERROR,
        message: t('Card:editor.validation.title.required'),
        icon: <PhosphorIcon.WarningCircle />,
      });
      return;
    }

    const editorContent = editor?.getJSON();

    if (editorContent) {
      const validationError = validateEditorContent(editorContent);
      if (validationError) {
        return notify({
          type: NotifierType.ERROR,
          message: t('Card:editor.validation.content.invalidContent', {
            error: validationError,
          }),
          icon: <PhosphorIcon.WarningCircle />,
        });
      }
    }

    const content = (editor?.getText() || '').trim();
    if (
      (!isDirty && !titleDirty && !cardPermissionsDirty && !tagsDirty) ||
      content === TEMP_PLACEHOLDER_TEXT
    ) {
      return notify({
        type: NotifierType.ERROR,
        message: t('Card:editor.validation.content.required'),
        icon: <PhosphorIcon.WarningCircle />,
      });
    }

    try {
      await saveCard();

      notify({
        type: NotifierType.SUCCESS,
        message: t('Card:editor.modal.saveSuccess'),
        icon: <PhosphorIcon.Check />,
      });

      setTitleDirty(false);
      onClose(true);
    } catch (error) {
      notify({
        type: NotifierType.ERROR,
        message: t('Card:editor.modal.saveError'),
        icon: <PhosphorIcon.WarningCircle />,
      });
    }
  }, [
    isCardTitleOptional,
    title,
    editor,
    isDirty,
    titleDirty,
    cardPermissionsDirty,
    tagsDirty,
    notify,
    t,
    saveCard,
    onClose,
  ]);

  const handleSetFullScreen = useCallback(() => {
    setFullScreen(!fullScreen);
  }, [fullScreen]);

  const handleModalClose = useCallback(
    (didSave?: boolean) => {
      clearBackup();
      onClose(didSave);
    },
    [onClose, clearBackup],
  );

  const CancelButton = (
    <Button
      variant="flat-form"
      label={t('Card:editor.modal.cancel')}
      onClick={() => handleModalClose()}
      margin={{ right: 'small' }}
    />
  );

  const loadingComp =
    isLoading && !failedLoading ? <CardEditorLoading /> : null;
  const failedComp = failedLoading ? (
    <CardEditorFailedLoading onClose={handleModalClose} />
  ) : null;

  const handleOnVisibilityGroupSelected = useCallback(
    (cardPermissionCardsData?: GetCardPermissionCardsDataResult) =>
      handleUpdateVisibilityGroups(card, cardPermissionCardsData),
    [handleUpdateVisibilityGroups, card],
  );

  return (
    <>
      <Modal
        showCloseButton={true}
        width={fullScreen ? 'auto' : width}
        full={fullScreen}
        responsive={!fullScreen}
        background={tokens.color.neutral.white.main}
      >
        <Box
          flex={false}
          fill="horizontal"
          direction="row"
          border={{
            side: 'bottom',
            color: tokens.color.neutral.lightgrey.main,
          }}
          align="center"
          pad={{
            horizontal: tokens.spacing.medium,
            vertical: tokens.spacing.small,
          }}
        >
          <PhosphorIcon.PencilSimple
            size={tokens.iconSize.medium}
            weight="regular"
          />
          <TextInput
            ref={setTextInputRef}
            size="large"
            a11yTitle={t('Card:editor.title.placeholder')}
            onChange={handleTitleChange}
            value={title}
            placeholder={t('Card:editor.title.placeholder')}
            style={{
              fontSize: tokens.fontSize.bodyFont.xlarge,
              fontWeight: tokens.fontWeight.semibold,
              border: 'none',
            }}
            data-test-id={TEST_IDS.cardEditor.titleInput}
            onKeyDown={handleTitleKeyDown}
          />
          <Box onClick={() => handleModalClose()} margin={{ left: 'small' }}>
            <PhosphorIcon.X
              size={tokens.iconSize.medium}
              style={{ cursor: 'pointer' }}
              weight="regular"
            />
          </Box>
        </Box>
        <Box
          flex={false}
          direction="row"
          align="center"
          style={{ position: 'relative', zIndex: 9 }}
          pad={{
            horizontal: tokens.spacing.small,
            top: tokens.spacing.medium,
            bottom: tokens.spacing.xlarge,
          }}
        >
          {!failedLoading && !isLoading && editor && (
            <>
              <CardEditorToolbar
                editor={editor}
                cardId={card?.id}
                profileId={card?.board?.profileId}
              />
              <Box
                direction="row"
                gap="xsmall"
                justify="end"
                height="xsmall"
                align="center"
                flex={{ shrink: 0, grow: 1 }}
              >
                <Button
                  variant="plain"
                  size="small"
                  focusIndicator={false}
                  onClick={handleSetFullScreen}
                  // Temporary disable fullscreen button
                  style={{ display: 'none' }}
                >
                  <PhosphorIcon.CornersOut />
                </Button>
              </Box>
            </>
          )}
        </Box>

        <Modal.Content
          background={tokens.color.neutral.white.main}
          alignContent={fullScreen ? 'center' : undefined}
          wrap={true}
          overflow="hidden"
          pad="none"
        >
          {!failedLoading && !isLoading ? (
            <Box
              ref={editorContainerRef}
              suppressContentEditableWarning={true}
              overflow={{ vertical: 'scroll' }}
              background={tokens.color.neutral.white.main}
              pad={{
                horizontal: `calc(${tokens.spacing.xxlarge} + ${tokens.spacing.medium})`,
                vertical: tokens.spacing.medium,
              }}
              className="klue-content-service-editor"
            >
              <KlueEditorContent editor={editor} topbar={false} />
              <Box
                margin={{ vertical: tokens.spacing.medium }}
                flex={{ shrink: 0 }}
              >
                <CardEditorTags
                  tags={tags}
                  onAddTag={addTag}
                  onRemoveTag={removeTag}
                />
              </Box>
            </Box>
          ) : null}
          {loadingComp}
          {failedComp}
        </Modal.Content>
        {!failedLoading && !isLoading ? (
          <CardEditorCardInfo card={card} />
        ) : null}
        {!failedLoading ? (
          <Box
            direction="row"
            flex={{ shrink: 0 }}
            margin={{ horizontal: 'small' }}
            justify="end"
            border={{
              side: 'top',
              color: tokens.color.neutral.lightgrey.main,
            }}
          >
            {isLoading ? (
              <Box margin={{ bottom: tokens.spacing.small }}>
                {CancelButton}
              </Box>
            ) : (
              <KlueCardVisibilityGroups
                cardId={card?.id}
                isDraft={isDraft}
                flex="grow"
                visibilityGroups={visibilityGroups}
                allAccess={allAccess}
                zIndex={10}
                onVisibilityGroupSelected={handleOnVisibilityGroupSelected}
              >
                {CancelButton}
                <Button
                  a11yTitle={
                    isSaving
                      ? t('Card:editor.modal.saving')
                      : t('Card:editor.modal.save')
                  }
                  variant="highlight-form"
                  label={
                    isSaving
                      ? t('Card:editor.modal.saving')
                      : t('Card:editor.modal.save')
                  }
                  onClick={handleSave}
                  disabled={
                    isSaving ||
                    (!isDirty &&
                      !titleDirty &&
                      !cardPermissionsDirty &&
                      !tagsDirty)
                  }
                  data-test-id={TEST_IDS.cardEditor.saveButton}
                />
              </KlueCardVisibilityGroups>
            )}
          </Box>
        ) : null}
      </Modal>
    </>
  );
};

export default React.memo(CardEditor, areCardEditorPropsEqual);

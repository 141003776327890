import { useKlueEditor, getKlueExtensions } from '@kluein/content-editor';
import { useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { useAuth } from 'contexts/auth';
import useCardVisibilityGroups from 'hooks/use-card-visibility-groups/useCardVisibilityGroups';
import store from 'store';
import {
  type CreateCardPayload,
  useCreateCardMutation,
  useDeleteCardMutation,
  useUpdateCardMutation,
} from 'store/api/cards';
import getSignedUrl from 'utils/s3/get-signed-url';

import { useCardBackup } from './useCardBackup';
import useEditTags from './useEditTags';

import { parseBlocks } from '../CardEditor.utils';
import { DynamicBlock } from '../extensions/dynamic-blocks';
import {
  parseDynamicBlocks,
  parseDynamicBlocksInHtml,
} from '../extensions/dynamic-blocks/dynamic-block.utils';

import type { ColorConfig } from '@kluein/content-editor/extensions';
import type { PresignedPostResponse } from '@kluein/content-editor/helpers';
import type {
  CardDataType,
  CardType,
  UpdateCardsType,
  UserType,
} from 'api/api.types';
import type { Dispatch, RootState } from 'store/store.types';

export type UseCardEditorOptions = {
  initialCard?: CardType;
  editorContainerRef?: React.RefObject<HTMLDivElement>;
  profileId?: number;
  onContentRestore?: () => void;
};

const checkForDynamicBlocks = (cardData: CardDataType) => {
  return cardData.textJson?.some((block) => block.type === 'formula');
};

function useCardEditor({
  initialCard,
  editorContainerRef,
  profileId,
  onContentRestore,
}: UseCardEditorOptions) {
  const dispatch = useDispatch<Dispatch>();
  const { company } = useAuth();

  const user = useSelector<RootState, UserType | null>(
    (state) => state.auth.user,
  );
  const [isDirty, setIsDirty] = useState(false);
  let initialContent =
    initialCard?.data.jsonData || initialCard?.data.textHtml || '';
  const initialTitle = initialCard?.data.name || '';
  const { t } = useTranslation(['Card']);
  const [searchParams] = useSearchParams();
  const boardId = parseInt(searchParams.get('laneId') || '0', 10);
  const [editedTitle, setEditedTitle] = useState(initialTitle);
  const [isSaving, setIsSaving] = useState(false);
  const [deleteCard] = useDeleteCardMutation();
  const { saveBackup, getBackup, shouldRestoreBackup, clearBackup } =
    useCardBackup(initialCard?.id);
  const {
    isDraft,
    allAccess,
    visibilityGroups,
    handleUpdateVisibilityGroups,
    isDirty: cardPermissionsDirty,
  } = useCardVisibilityGroups({
    card: initialCard,
  });

  const currentRivalId = useSelector<RootState, number | undefined>(
    (state) => store.select.profiles.currentProfileRival(state)?.id,
  );

  const { editedTags, tagsDirty, addTag, removeTag } = useEditTags(
    initialCard?.tags,
  );
  const hasDynamicBlocks =
    initialCard?.data && checkForDynamicBlocks(initialCard.data);

  // Backward compatibility for dynamic blocks
  if (hasDynamicBlocks) {
    initialContent = parseDynamicBlocks(initialCard.data);
  }

  const getHighlightColors = useCallback((): ColorConfig | undefined => {
    const { text, background } = company?.companyData?.colors || {};
    if (!text || !Array.isArray(text)) return undefined;

    if (background && Array.isArray(background)) {
      return { text, background };
    }

    return { text };
  }, [company]);

  useEffect(() => {
    setEditedTitle(initialCard?.data?.name || '');
  }, [initialCard]);
  const handleTitleChange = useCallback((title: string) => {
    setEditedTitle(title);
    saveBackup(title, editor);
    /* TODO NWA-807: Resolve or continue disabling ESLint warning below */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
  const sortedCards = useSelector<RootState, CardType[]>((state) => {
    const bCards: CardType[] = [];
    state.cards.byId.forEach((card) => {
      if (card?.board?.id !== boardId) {
        return;
      }
      bCards.push(card);
    });
    return bCards.sort(
      (a, b) => parseFloat(a.viewOrder) - parseFloat(b.viewOrder),
    );
  });

  const handleDeleteCard = async (cardId: number) => {
    try {
      await deleteCard(cardId);

      dispatch.cards.delete({ cardIds: [cardId] });

      if (initialCard?.board?.id) {
        dispatch.boards.deleteCards({ cardIds: [cardId] });
      }

      return true;
    } catch (error) {
      throw new Error(`Failed to delete card with ID ${cardId}`);
    }
  };

  const extensions = useMemo(() => {
    return (
      getKlueExtensions({
        placeholder: t('Card:editor.placeholder'),
        highlight: {
          colors: getHighlightColors(),
        },
        image: {
          s3UploadConfig: {
            bucketName: import.meta.env.VITE_S3_BUCKET_IMAGE_UPLOAD,
            getSignedUrl: (file: File) =>
              new Promise((resolve, reject) => {
                return getSignedUrl(file, (result: unknown) => {
                  if (!result) {
                    return reject(new Error('Failed to get presigned URL'));
                  }
                  return resolve(result as PresignedPostResponse);
                });
              }),
          },
        },
      }) || []
    );
  }, [getHighlightColors, t]);

  const editor = useKlueEditor({
    content: initialContent,
    editorContainerRef,

    onUpdate: () => {
      saveBackup(editedTitle, editor);
      setIsDirty(true);
    },
    floatingMenu: ['highlight', 'bold', 'italic', 'link'],
    extensions: [
      ...extensions,
      DynamicBlock.configure({
        cardId: initialCard?.id,
        profileId: initialCard?.board?.profileId || profileId || 0,
        currentRivalId,
      }),
    ],
  });

  const [updateCard, updateState] = useUpdateCardMutation();
  const [createCard, createState] = useCreateCardMutation();

  useEffect(() => {
    const backup = getBackup();

    if (backup && shouldRestoreBackup(backup) && editor) {
      editor.commands.setContent(backup.content);
      setEditedTitle(backup.title);

      onContentRestore?.();
      return;
    }

    if (initialCard?.data && editor) {
      const parsedContent = hasDynamicBlocks
        ? parseDynamicBlocks(initialCard.data)
        : parseBlocks(initialCard.data);
      editor.commands.setContent(parsedContent);
    }
    /* TODO NWA-807: Resolve or continue disabling ESLint warning below */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [editor, hasDynamicBlocks, initialCard?.data]);

  const handleSaveCard = useCallback(async () => {
    setIsSaving(true);

    try {
      if (!editor) {
        throw new Error('Editor is not initialized');
      }
      const cardContent = editor.getJSON();
      const cardHtml = editor.getHTML();
      const viewOrder = parseFloat(searchParams.get('viewOrder') || '0.0');
      const tags = editedTags.map((tag) => tag.id);

      const hasDynamicBlocks =
        initialCard?.data && checkForDynamicBlocks(initialCard.data);

      if (!!initialCard) {
        const cardsParam: UpdateCardsType = {
          cardIds: [initialCard.id],
          data: {
            name: editedTitle,
            textHtml: cardHtml,
            jsonData: cardContent,
            hasDynamicBlocks,
            useNewEditor: true,
          },
          viewOrder,
          boardId,
          allAccess,
          isDraft,
          tags,
        };

        cardsParam.data.textHtml = parseDynamicBlocksInHtml(cardHtml);

        if (!allAccess && !isDraft) {
          cardsParam.visibilityGroups = visibilityGroups.map((vg) => vg.id);
        }

        const resultArray = await updateCard(cardsParam).unwrap();

        if (!resultArray?.length) {
          throw new Error('Failed to update card');
        }

        const updatedCard = { ...resultArray[0] };
        // need to explicity copy these since they are frozen
        if (updatedCard.visibilityGroups) {
          updatedCard.visibilityGroups = [...updatedCard.visibilityGroups];
        }

        dispatch.cards.updateAndParseCardsData({ updatedCards: [updatedCard] });
      } else {
        const payload: CreateCardPayload = {
          data: {
            name: editedTitle,
            textHtml: parseDynamicBlocksInHtml(cardHtml),
            jsonData: cardContent,
            useNewEditor: true,
            hasDynamicBlocks,
          },
          viewOrder,
          boardId,
          author: user?.id || 0,
          templateName: 'CardHTML',
          allAccess,
          isDraft,
          tags,
        };

        if (!allAccess && !isDraft) {
          payload.visibilityGroups = visibilityGroups.map((vg) => vg.id);
        }

        const resultCard = await createCard(payload).unwrap();
        const newCard = {
          ...resultCard,
        };

        // need to explicity copy these since they are frozen
        if (newCard.visibilityGroups) {
          newCard.visibilityGroups = [...newCard.visibilityGroups];
        }

        if (!newCard) {
          throw new Error('Failed to create card');
        }

        dispatch.cards.addAndParseNewCard({
          newCard,
          boardCards: sortedCards,
        });
      }
    } catch (error) {
      return Promise.reject(error);
    } finally {
      clearBackup();
      setIsSaving(false);
    }
    /* TODO NWA-807: Resolve or continue disabling ESLint warning below */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    editor,
    searchParams,
    initialCard,
    editedTitle,
    allAccess,
    isDraft,
    editedTags,
    updateCard,
    dispatch.cards,
    visibilityGroups,
    user?.id,
    createCard,
    sortedCards,
    boardId,
  ]);

  return {
    editor,
    saveCard: handleSaveCard,
    title: editedTitle,
    isDraft,
    isDirty,
    allAccess,
    visibilityGroups,
    cardPermissionsDirty,
    tags: editedTags,
    tagsDirty,
    updateTitle: handleTitleChange,
    deleteCard: handleDeleteCard,
    handleUpdateVisibilityGroups,
    addTag,
    removeTag,
    isSaving: isSaving || updateState.isLoading || createState.isLoading,
    isError: updateState.isError || createState.isError,
    isSuccess: updateState.isSuccess || createState.isSuccess,
    clearBackup,
  };
}

export { useCardEditor };

/* eslint-disable max-lines */
const TEST_IDS = {
  alertsDashboard: {
    card: 'alerts-dashboard__card',
    container: 'alerts-dashboard__container',
    cta: 'alerts-dashboard__cta',
    logoContainer: 'alerts-dashboard__logo-container',
    rivalCount: 'alerts-dashboard__rival-count',
  },
  appNavBar: {
    appNavBarMenu: 'appNavBar-menu',
    appNavBarMenuResponsive: 'appNavBar-menu-responsive',
    globalSearch: {
      container: 'navbar-globalSearch-container',
      searchContext: {
        container: 'navbar-globalSearch-context-container',
        items: {
          alerts: 'navbar-globalSearch-context-alerts',
          cards: 'navbar-globalSearch-context-cards',
        },
      },
      suggestions: {
        container: 'navbar-globalSearch-suggestions-container',
        list: 'navbar-globalSearch-suggestions-list',
      },
    },
    navBarLinks: {
      alerts: 'appNavBar-navBarLinks-alerts',
      boards: 'appNavBar-navBarLinks-boards',
      competitiveAnalytics: 'appNavBar-navBarLinks-competitiveAnalytics',
      consumerUsage: 'appNavBar-navBarLinks-consumerUsage',
      curatorActivity: 'appNavBar-navBarLinks-curatorActivity',
      digest: 'appNavBar-navBarLinks-digest',
      feed: 'appNavBar-navBarLinks-feed',
      insights: 'appNavBar-navBarLinks-insights',
      integrations: 'appNavBar-navBarLinks-integrations',
      monitors: 'appNavBar-navBarLinks-monitors',
      salesCalls: 'appNavBar-navBarLinks-salesCalls',
      threatAnalysis: 'appNavBar-navBarLinks-threatAnalysis',
      winLoss: 'appNavBar-navBarLinks-winLoss',
      composer: 'appNavBar-navBarLinks-companyProfile',
    },
    newCuratorNavBarLink: 'appNavBar-newCuratorNavBarLink',
    userMenu: {
      actionButton: 'navBar-userMenu-button',
      container: 'navbar-userMenu-container',
      insightsButton: 'navBar-userMenu-insights-button',
      knowledgeHubButton: 'navBar-userMenu-knowledgeHub-button',
      reportsButton: 'navBar-userMenu-reports-button',
      revertImpersonate: 'navBar-userMenu-revertImpersonate',
      signoutButton: 'navBar-userMenu-signout-button',
      switchInstance: 'navBar-userMenu-switchInstance',
      winLossButton: 'navBar-userMenu-win-loss-button',
    },
  },
  askKlue: {
    answer: {
      footerContainer: 'ask-klue--answer-footer-button',
      showLessSourceButton: 'ask-klue--answer-show-less-source-button',
      showMoreSourceButton: 'ask-klue--answer-show-more-source-button',
      source: 'ask-klue--answer-source',
      sourceCount: 'ask-klue--answer-source-count',
      inlineCitation: 'ask-klue--answer-inline-citation',
    },
    answerModal: {
      closeButton: 'ask-klue-answer-modal-close-button',
      container: 'ask-klue-answer-modal-container',
      copyButton: 'ask-klue-answer-modal-copy-button',
      error: 'ask-klue-answer-modal-error',
      loading: 'ask-klue-answer-modal-loading',
      negativeFeedbackButton: 'ask-klue-answer-modal-negative-feedback-button',
      positiveFeedbackButton: 'ask-klue-answer-modal-positive-feedback-button',
      tryAgainButton: 'ask-klue--try-again-button',
    },
    viewAs: {
      select: 'ask-klue-view-as-select',
    },
    button: 'ask-klue-button',
    container: 'ask-klue-container',
    inputContainer: 'ask-klue-input-container',
    text: 'ask-klue-text',
    textInput: 'ask-klue-text-input',
    dashboardConsumerToggle: 'ask-klue-dashboard-consumer-toggle',
    feedback: {
      textInput: 'ask-klue-feedback-text-input',
    },
  },
  avatarSource: 'avatar-source',
  battlecard: {
    battlecardInfo: {
      avatar: {
        default: 'battlecard-battleCardInfo-avatar-default',
        user: 'battlecard-battleCardInfo-avatar-user',
      },
      container: 'battlecard-battleCardInfo-container',
      date: 'battlecard-battleCardInfo-date',
      link: 'battlecard-battleCardInfo-link',
      title: 'battlecard-battleCardInfo-title',
    },
    closeButton: 'battlecard-layout-switcher-close-button',
    container: 'battlecard-containter',
    edit: {
      actions: {
        menu: 'battlecard-edit-actions-menu',
        edit: 'battlecard-edit-actions-edit',
        publish: 'battlecard-edit-actions-publish',
        delete: 'battlecard-edit-actions-delete',
      },
      header: 'battlecard-edit-header',
      titleInput: 'battlecard-edit-title-input',
      cancel: 'battlecard-edit-cancel',
      save: 'battlecard-edit-save',
      layoutDrop: 'battlecard-edit-layout-drop',
      deleteBattlecardDialog: 'battlecardEditor-deleteBattleCardDialog',
      deleteCard: 'battlecard-edit-delete-card',
    },
    empty: 'battlecard-empty',
    gridLayout: {
      card: 'battlecard-gridLayout-card',
      grid: 'battlecard-gridLayout-grid',
    },
    header: {
      container: 'battlecard-header-container',
      rivalLogo: 'battlecard-header-rivalLogo',
    },
    heroLayout: {
      card: 'battlecard-heroLayout-card',
      grid: 'battlecard-heroLayout-grid',
    },
    layoutSwitcher: 'battlecard-layout-switcher',
    masonryLayout: {
      card: 'battlecard-masonryLayout-card',
      grid: 'battlecard-masonryLayout-grid',
    },
    notFound: 'battlecard-not-found',
    stackerLayout: {
      button: 'battlecard-stackerLayout-button',
      card: 'battlecard-stackerLayout-card',
      cardNav: 'battlecard-stackerLayout-cardNav',
      container: 'battlecard-stackerLayout',
    },
    stackerNavigator: {
      container: 'battlecard-stackerNavigator-container',
      counter: 'battlecard-stackerNavigator-counter',
      nextLink: 'battlecard-stackerNavigator-nextLink',
      prevLink: 'battlecard-stackerNavigator-prevLink',
    },
  },
  bulkEdit: {
    allDoneButton: 'bulkEdit-allDoneButton',
    cancelButton: 'bulkEdit-header-cancelButton',
    cardPermissions: {
      checkBoxText: 'bulkEdit-cardPermissions-checkboxText',
      container: 'bulkEdit-cardPermissions',
      curatorsOnlyRadioButton:
        'bulkEdit-cardPermissions-curatorsOnlyRadioButton',
      everyOneRadioButton: 'bulkEdit-cardPermissions-everyoneRadioButton',
      groupsRadioButton: 'bulkEdit-cardPermissions-groupsRadioButton',
    },
    cardTags: {
      addOrRemoveRadioGroup: 'bulkEdit-cardTags-addOrRemoveRadioGroup',
      addRadio: 'bulkEdit-cardTags-addRadio',
      checkboxContainer: 'bulkEdit-cardTags-checkbox',
      checkboxesContainer: 'bulkEdit-cardTags-checkboxes',
      container: 'bulkEdit-cardTags',
      deselectAllButton: 'bulkEdit-cardTags-deselectAllButton',
      noResultText: 'bulkEdit-cardTags-noResultText',
      removeRadio: 'bulkEdit-cardTags-removeRadio',
      searchInput: 'bulkEdit-cardTags-searchInput',
    },
    cardDelete: {
      container: 'bulkEdit-cardDelete',
    },
    completeButton: 'bulkEdit-header-completeButton',
    container: 'bulkEdit-container',
    continueEditingButton: 'bulkEdit-continueEditingButton',
    deselectAllButton: 'bulkEdit-header-deselectAllButton',
    header: 'bulkEdit-header',
    headerTitle: 'bulkEdit-headerTitle',
    loadingSkeleton: 'bulkEdit-header-loadingSkeleton',
    select: 'bulkEdit-select',
    selectedCards: 'bulkEdit-header-selectedCards',
  },
  callInsights: {
    callCard: 'call-insights-call-card',
    callDialogBody: 'call-dialog-body',
    callDialogHeader: 'call-dialog-header',
    callMetaHeader: 'call-meta-header',
    competitorMention: 'competitor-mention',
    customCloseButton: 'custom-close-button',
    more: 'more-flag',
    totalUniqueCompetitors: 'total-competitors',
    banner: 'call-insights-banner',
    summarizeWidget: {
      deleteSummaryButton: 'callInsights-summarizeWidget-deleteButton',
    },
  },
  cardEmbed: 'cardEmbed-container',
  cardEditor: {
    editorContainer: 'cardEditor-editorContainer',
    cardLoadFailed: 'cardEditor-cardLoadFailed',
    cardLoading: 'cardEditor-cardLoading',
    titleInput: 'cardEditor-titleInput',
    saveButton: 'cardEditor-saveButton',
    deleteCardDialog: 'cardEditor-deleteCardDialog',
    cardInfo: 'cardEditor-cardInfo',
  },
  cardViewer: {
    breadcrumb: 'cardViewer-breadcrumb',
    closeButton: 'cardViewer-closeButton',
    container: 'cardViewer-container',
    content: 'cardViewer-content',
    error: 'cardViewer-error',
    header: 'cardViewer-header',
    loading: 'cardViewer-loading',
    singleCardViewer: {
      backButton: 'cardViewer-singleCardViewer-backButton',
      closeButton: 'cardViewer-singleCardViewer-closeButton',
      container: 'cardViewer-singleCardViewer-container',
      editButton: 'cardViewer-singleCardViewer-editButton',
      error: 'cardViewer-singleCardViewer-error',
      loading: 'cardViewer-singleCardViewer-loading',
      modal: 'cardViewer-singleCardViewer-modal',
    },
  },
  commandCenter: {
    bulkActions: {
      actionsBar: {
        addToDigest: 'commandCenter-bulk-actions-actions-bar-add-to-digest',
        archive: 'commandCenter-bulk-actions-actions-bar-archive',
        container: 'commandCenter-bulk-actions-actions-bar-container',
        count: 'commandCenter-bulk-actions-actions-bar-count',
        delete: 'commandCenter-bulk-actions-actions-bar-delete',
        dialog: 'commandCenter-bulk-actions-actions-bar-dialog',
        dialogProgressBar:
          'commandCenter-bulk-actions-actions-bar-dialog-progress-bar',
        dropContent: 'commandCenter-bulk-actions-actions-bar-drop-content',
        prioritize: 'commandCenter-bulk-actions-actions-bar-prioritize',
        publish: 'commandCenter-bulk-actions-actions-bar-publish',
        rival: 'commandCenter-bulk-actions-actions-bar-rival',
        state: 'commandCenter-bulk-actions-actions-bar-state',
        topic: 'commandCenter-bulk-actions-actions-bar-topic',
      },
      selectAllBar: {
        container: 'commandCenter-bulk-actions-select-all-bar-container',
      },
    },
    errorDisplayer: {
      button: 'commandCenter-error-displayer-button',
      container: 'commandCenter-error-displayer-container',
      title: 'commandCenter-error-displayer-title',
    },
    intelList: {
      allItemsLoaded: 'commandCenter-intel-list-all-items-loaded',
      container: 'commandCenter-intel-list',
      doneStateAnimation: 'commandCenter-intel-list-done-state-animation',
      emptyContainer: 'commandCenter-intel-list-empty',
      emptyStateHelpBox: 'commandCenter-intel-list-empty-state-help-box',
      filteredListCount: 'commandCenter-intel-list-filtered-list-count',
      loader: 'commandCenter-intel-list-loader',
      triageContainer: 'commandCenter-intel-list-triage-container',
      virtualizedInnerContainer:
        'commandCenter-intel-list-virtualized-inner-container',
      virtualizedOuterContainer:
        'commandCenter-intel-list-virtualized-outer-container',
    },
    intelListItem: {
      actionMenu: 'commandCenter-intel-list-item-action-menu',
      addContextMenu: 'commandCenter-intel-list-item-add-context-menu',
      addToDigestButton: 'commandCenter-intel-list-item-add-to-digest-button',
      badges: 'commandCenter-intel-list-item-badges',
      dateDivider: 'commandCenter-dateDivider',
      item: 'commandCenter-intel-list-item',
      moveMenu: 'commandCenter-intel-list-item-move-menu',
      multipleDigests: {
        close: 'commandCenter-intel-list-item-multiple-digests-close',
        header: 'commandCenter-intel-list-item-multiple-digests',
        inputCheckbox:
          'commandCenter-intel-list-item-multiple-digests-inputCheckbox',
        save: 'commandCenter-intel-list-item-multiple-digests-save',
      },
      publishButton: 'commandCenter-intel-list-item-publish-button',
      publishedToFeed: 'commandCenter-intel-list-item-published-to-feed',
    },
    intelListTopBar: {
      boardDropButton: 'intel-list-top-bar-board-drop-button',
      container: 'intel-list-top-bar-container',
      expandedFilterList: 'intel-list-top-bar-expanded-filter-list',
      filterList: 'intel-list-top-bar-filter-list',
      filterSet: 'intel-list-top-bar-filter-set',
      intelListActionsButtons: 'intel-list-actions-buttons',
      intelListKeyBoardShortcuts: 'intel-list-keyboard-shortcuts',
      savedViews: {
        actionsDropdown: {
          container:
            'intel-list-top-bar-saved-views-actions-dropdown-container',
          createNewViewButton:
            'intel-list-top-bar-saved-views-actions-dropdown-create-new-view-button',
          deleteButton:
            'intel-list-top-bar-saved-views-actions-dropdown-delete-button',
          renameButton:
            'intel-list-top-bar-saved-views-actions-dropdown-rename-button',
          splitButton:
            'intel-list-top-bar-saved-views-actions-dropdown-split-button',
        },
        cancelNameButton: 'intel-list-top-bar-saved-views-cancel-name-button',
        container: 'intel-list-top-bar-saved-views-container',
        createNewViewButton:
          'intel-list-top-bar-saved-views-create-new-view-button',
        listOfViewsDropdownButton:
          'intel-list-top-bar-saved-views-list-of-views-dropdown-button',
        listOfViewsDropdownContainer:
          'intel-list-top-bar-saved-views-list-of-views-dropdown-container',
        myNewIntelCount: 'intel-list-top-bar-saved-views-my-new-intel-count',
        resetButton: 'intel-list-top-bar-saved-views-reset-button',
        savedSplitButton: 'intel-list-top-bar-saved-views-saved-split-button',
        saveNameButton: 'intel-list-top-bar-saved-views-save-name-button',
        saveSplitButton: 'intel-list-top-bar-saved-views-save-split-button',
      },
      titleBox: 'intel-list-top-bar-titleBox',
      triageToggle: 'intel-list-triage-toggle',
      viewSettings: 'intel-list-top-bar-view-settings',
      viewSettingsDrop: 'intel-list-top-bar-view-settings-drop',
    },
    layout: 'commandCenter-layout',
    leftPane: {
      sectionLabel: 'commandCenter-left-pane-sectionLabel',
      sections: {
        savedViewsListContainer:
          'commamdCenter-left-pane-saved-views-list-container',
      },
    },
    loadingState: 'commandCenter-loading-state',
    rightPane: {
      sections: {
        boardGroups: 'commandCenter-right-pane-section-boardGroups',
        boards: 'commandCenter-right-pane-section-boards',
        dateRange: 'commandCenter-right-pane-section-dateRange',
        emptyUserBoardsNotice: {
          anchor:
            'commandCenter-right-pane-section-myBoards_empty-user-boards-notice--anchor',
          container:
            'commandCenter-right-pane-section-myBoards_empty-user-boards-notice',
          description:
            'commandCenter-right-pane-section-myBoards_empty-user-boards-notice--description',
          title:
            'commandCenter-right-pane-section-myBoards_empty-user-boards-notice--title',
        },
        myBoards: 'commandCenter-right-pane-section-myBoards',
        priorities: 'commandCenter-right-pane-section-priorities',
        processedByFilter: 'commandCenter-right-pane-section-processedByFilter',
        processedByToggle: 'commandCenter-right-pane-section-processedByToggle',
        sort: 'commandCenter-right-pane-section-sort',
        sources: 'commandCenter-right-pane-section-sources',
        states: 'commandCenter-right-pane-section-states',
        topics: 'commandCenter-right-pane-section-topics',
        types: 'commandCenter-right-pane-section-types',
      },
    },
    shareToSlack: {
      error: 'commandCenter-share-to-slack-error',
      modal: 'commandCenter-share-to-slack-modal',
      modalContent: {
        addWhyItMatters:
          'commandCenter-share-to-slack-modal-content-addWhyItMatters',
        container: 'commandCenter-share-to-slack-modal-content-container',
        intelBody: 'commandCenter-share-to-slack-modal-content-intelBody',
        intelSummary: 'commandCenter-share-to-slack-modal-content-intelSummary',
        whyItMatters: 'commandCenter-share-to-slack-modal-content-whyItMatters',
      },
    },
    triageMode: {
      actions: {
        archive: 'commandCenter-triage-actions-archive',
        back: 'commandCenter-triage-actions-back',
        delete: 'commandCenter-triage-actions-delete',
        important: 'commandCenter-triage-actions-important',
        interesting: 'commandCenter-triage-actions-interesting',
        skip: 'commandCenter-triage-actions-skip',
      },
      doneState: {
        archive: 'commandCenter-triage-mode-done-state-archive',
        buttonContainer:
          'commandCenter-triage-mode-done-state-button-container',
        container: 'commandCenter-triage-mode-done-state-container',
        delete: 'commandCenter-triage-mode-done-state-delete',
        important: 'commandCenter-triage-mode-done-state-important',
        interesting: 'commandCenter-triage-mode-done-state-interesting',
      },
      floatingExitTriageRow: {
        button: 'commandCenter-floating-exit-triage-row-button-button',
        container: 'commandCenter-floating-exit-triage-row-container',
      },
      smartSetInfo: {
        container: 'commandCenter-smart-set-info-container',
        count: 'commandCenter-smart-set-info-count',
        doneAction: 'commandCenter-smart-set-info-done-action',
        viewAllWhenCollapsed: 'commandCenter-smart-set-info-viewAll',
      },
      triageActionsBar: 'commandCenter-triage-actions-bar',
    },
    views: {
      newIntel: 'commandCenter-view-newIntel',
    },
    winLoss: {
      filters: {
        dateRange: 'commandCenter-winLoss-filters-dateRange',
      },
    },
  },
  commons: {
    intelPermalinkMetadata: {
      container: 'commons-intel-permalink-metadata-container',
    },
  },
  company: {
    landingPage: {
      profile: {
        battlecard: 'company-landing-page-profile__battlecard',
        battlecards: 'company-landing-page-profile__battlecards',
        card: 'company-landing-page-profile__card',
        insightCard: 'company-landing-page-profile__insight-card',
        loadingBattlecards: 'company-landing-page-profile__battlecards-loading',
        whatsNew: 'company-landing-page-profile__whats-new',
        whatsNewContainer: 'company-landing-page-profile__whats-new-container',
        loadingWhatsNew: 'company-landing-page-profile__whats-new-loading',
      },
    },
  },
  composer: {
    cards: {
      card: 'composer-cards-card',
      cardError: 'composer-cards-cardError',
      cardTitle: 'composer-cards-cardTitle',
      container: 'composer-cards-container',
      placeholder: 'composer-cards-placeholder',
      improve: {
        button: 'composer-cards-improve-button',
        submit: 'composer-cards-improve-submit',
        cancel: 'composer-cards-regenerate-cancel',
        textArea: 'composer-cards-improve-textArea',
      },
      regenerate: {
        button: 'composer-cards-regenerate-button',
        cancel: 'composer-cards-regenerate-cancel',
        submit: 'composer-cards-regenerate-submit',
      },
      retryButton: 'composer-cards-retry-button',
      supportLink: 'composer-cards-support-link',
      sourceItem: 'composer-cards-sourceItem',
    },
    modals: {
      deleteProject: {
        cancel: 'composer-modals-deleteProject-cancel',
        confirm: 'composer-modals-deleteProject-confirm',
      },
      sourcesError: {
        cancel: 'composer-modals-sourcesError-cancel',
        continue: 'composer-modals-sourcesError-continue',
      },
      sourcesProcessing: {
        cancel: 'composer-modals-sourcesProcessing-cancel',
        continue: 'composer-modals-sourcesProcessing-continue',
        resolved: 'composer-modals-sourcesProcessing-resolved',
      },
      projectPublish: {
        cancel: 'composer-modals-projectPublish-cancel',
        container: 'composer-modals-projectPublish-container',
        publish: 'composer-modals-projectPublish-publish',
      },
      projectPublishSuccess: {
        cancel: 'composer-modals-projectPublishSuccess-cancel',
        container: 'composer-modals-projectPublishSuccess-container',
        redirect: 'composer-modals-projectPublishSuccess-redirect',
      },
    },
    project: {
      fetchError: 'composer-project-fetchError',
      fetchErrorReload: 'composer-project-fetchErrorReload',
      generate: 'composer-project-generate',
    },
    projectNavbar: {
      actions: 'composer-projectNavbar-actions',
      cancelGeneration: 'composer-proejctNavbar-cancelGeneration',
      deleteProject: 'composer-projectNavbar-deleteProject',
      name: 'composer-projectNavbar-name',
      generate: 'composer-projectNavbar-generate',
      saveDraft: 'composer-projectNavbar-saveDraft',
      sendToBoard: 'composer-projectNavbar-sendToBoard',
    },
    projectNavigation: {
      settings: 'composer-project-navigation-settings',
      company_overview: 'composer-project-navigation-company_overview',
      positioning_and_target_customer:
        'composer-project-navigation-positioning_and_target_customer',
      why_we_win: 'composer-project-navigation-why_we_win',
      why_we_lose: 'composer-project-navigation-why_we_lose',
    },
    projectsList: {
      empty: 'composer-projects-navigation-empty',
      error: 'composer-projects-navigation-error',
      list: 'composer-projects-navigation-list',
      loading: 'composer-projects-navigation-loading',
      loadMoreButton: 'composer-projects-navigation-loadMoreButton',
    },
    projectsNavigation: {
      createButton: 'composer-projects-navigation-createButton',
      empty: 'composer-projects-navigation-empty',
      error: 'composer-projects-navigation-error',
      list: 'composer-projects-navigation-list',
      loading: 'composer-projects-navigation-loading',
      loadMoreButton: 'composer-projects-navigation-loadMoreButton',
    },
    sentiment: {
      buttons: {
        negative: 'composer-sentiment-buttons-negative',
        positive: 'composer-sentiment-buttons-positive',
      },
      container: 'composer-sentiment-container',
      modal: {
        cancel: 'composer-sentiment-modal-cancel',
        checkbox: 'composer-sentiment-modal-checkbox',
        submit: 'composer-sentiment-modal-submit',
        textarea: 'composer-sentiment-modal-textarea',
      },
    },
    settings: {
      files: {
        checkbox: 'composer-settings-files-checkbox',
        empty: 'composer-settings-files-empty',
        fileInput: 'composer-settings-files-fileInput',
        upload: 'composer-settings-files-upload',
      },
      rivalSelect: 'composer-settings-rivalSelect',
      sources: {
        fetchError: 'composer-settings-sources-fetchError',
        fetchErrorReload: 'composer-settings-sources-fetchErrorReload',
        websiteAdd: 'composer-settings-sources-websiteAdd',
        websiteInput: 'composer-settings-sources-websiteInput',
        websiteSubmit: 'composer-settings-sources-websiteSubmit',
      },
      templates: {
        container: 'composer-settings-templates-container',
        label: 'composer-settings-templates-label',
        toggle: 'composer-settings-templates-toggle',
      },
      title: 'composer-settings-title',
    },
    sideNav: {
      breadcrumbs: 'composer-sideNav-breadcrumbs',
      collapseArrow: 'composer-sideNav-collapseArrow',
      container: 'composer-sideNav-container',
      content: 'composer-sideNav-content',
      item: 'composer-sideNav-item',
    },
    subNav: {
      draftChooser: {
        button: 'composer-subNav-draftChooser-button',
        container: 'composer-subNav-draftChooser-container',
      },
    },
  },
  competitiveDashboard: {
    card: 'competitive-dashboard__card',
    container: 'competitive-dashboard__container',
    cta: 'competitive-dashboard__cta',
    emptyState: 'competitive-dashboard__empty-state',
    logoContainer: 'competitive-dashboard__logo-container',
    openRevenue: 'competitive-dashboard__open-revenue',
    skeletonCard: 'competitive-dashboard__skeleton-card',
  },
  consumerNews: {
    card: {
      board: 'consumer-intel-card__board',
      boardLogo: 'consumer-intel-card__board-logo',
      container: 'consumer-intel-card',
      content: 'consumer-intel-card__content',
      defaultContentContainer: 'consumer-intel-card__default-content-container',
      footer: 'consumer-intel-card__footer',
      header: 'consumer-intel-card__header',
    },
    emptyContainer: 'consumer-news-emptyContainer',
    errorRetryButton: 'consumer-news-errorRetryButton',
    errorRetryContainer: 'consumer-news-errorContainer',
    extraBoards: {
      boardLogo: 'consumer-intel-extra-boards__board-logo',
      button: 'consumer-intel-extra-boards__button',
      container: 'consumer-intel-extra-boards__container',
    },
    loadingContainer: 'consumer-news-loadingContainer',
    newsGroupFilterTag: 'consumer-news-group-filter-tag',
    newsSection: 'consumer-news-news-section',
  },
  countText: 'count-text',
  dashboard: {
    boardsSection: 'dashboard-news-board-section',
    container: 'dashboard-container',
    favoritesSection: 'dashboard-favorites',
    filterDrops: {
      container: 'dashboard-filterDrops-container',
      group: {
        drop: 'dashboard-filterDrops-group-drop',
        dropButton: 'dashboard-filterDrops-group-dropButton',
        dropContent: 'dashboard-filterDrops-group-dropContent',
      },
      sort: {
        drop: 'dashboard-filterDrops-sort-drop',
        dropButton: 'dashboard-filterDrops-sort-dropButton',
        dropContent: 'dashboard-filterDrops-sort-dropContent',
      },
    },
    reportBanner: 'dashboard-container-report-banner',
    rivalGroup: {
      container: 'dashboard-rivalGroup-container',
      grid: 'dashboard-rivalGroup-grid',
      name: 'dashboard-rivalGroup-name',
      title: 'dashboard-rivalGroup-title',
    },
    rivalItem: {
      avatar: 'dashboard-rivalItem-avatar',
      battlecardLink: 'dashboard-rivalItem-battlecardLink',
      container: 'dashboard-rivalItem-container',
      logoContainer: 'dashboard-rivalItem-logoContainer',
      mainContainer: 'dashboard-rivalItem-mainContainer',
      name: 'dashboard-rivalItem-name',
      profileLink: 'dashboard-rivalItem-profileLink',
      actionMenu: 'dashboard-rivalItem-actionMenu',
      removeFromGroupButton: 'dashboard-rivalItem-removeFromGroupButton',
      publishButton: 'dashboard-rivalItem-publishButton',
      unpublishButton: 'dashboard-rivalItem-unpublishButton',
      draftTag: 'dashboard-rivalItem-draftTag',
      loading: 'dashboard-rivalItem-loading',
      unreadBadge: 'dashboard-rivalItem-unreadBadge',
    },
  },
  dashboardNews: {
    container: 'dashboard-news-container',
    desktop: {
      view: 'dashboard-news-desktop',
    },
    header: 'dashboard-news-header',
    mobile: {
      boardsButton: 'dashboard-news-board-button',
      favoritesButton: 'dashboard-news-favorites-button',
      newsButton: 'dashboard-news-news-button',
      view: 'dashboard-news-mobile',
    },
  },
  extension: {
    guide: {
      header: 'extension-guide-header',
      intro: 'extension-guide-intro',
      basics: 'extension-guide-basics',
      curator: 'extension-guide-curator',
    },
  },
  fallback: {
    pageNotAuthorized: 'fallback-pageNotAuthorized',
    pageNotFound: 'fallback-pageNotFound',
  },
  fieldIntel: {
    body: 'field-intel-body',
    container: 'field-intel-container',
    header: 'field-intel-header',
  },
  filterWidget: {
    selectAllButton: 'filterWidget-selectAllButton',
  },
  highlightText: 'highlight-text',
  integrations: {
    card: (slug: string) => `integrations-section-${slug}`,
    dataSync: {
      multiStageForm: {
        buttons: {
          back: 'integrations-data-sync-multi-stage-form-buttons-back',
          next: 'integrations-data-sync-multi-stage-form-buttons-next',
          skip: 'integrations-data-sync-multi-stage-form-buttons-skip',
          toggleObject: (object: string) =>
            `integrations-data-sync-multi-stage-form-buttons-toggle-${object}`,
          toggleFlag: (flag: string) =>
            `integrations-data-sync-multi-stage-form-buttons-toggle-${flag}`,
        },
        icons: {
          error: 'integrations-data-sync-multi-stage-form-icons-error',
          success: 'integrations-data-sync-multi-stage-form-icons-success',
        },
      },
    },
    gongCallInsights: {
      multiStageForm: {
        buttons: {
          back: 'integrations-gong-call-insights-multi-stage-form-buttons-back',
          done: 'integrations-gong-call-insights-multi-stage-form-buttons-done',
        },
        icons: {
          error: 'integrations-gong-call-insights-multi-stage-form-icons-error',
          success:
            'integrations-gong-call-insights-multi-stage-form-icons-success',
        },
        input: {
          misspellings:
            'integrations-data-sync-multi-stage-form-input-misspellings',
          tracker: 'integrations-data-sync-multi-stage-form-input-tracker',
        },
      },
    },
    header: 'integrations-view-header',
    integrationsButton: 'integrations-button',
    integrationsCard: 'integrations-card',
    integrationToggle: 'integrations-toggle',
    klueApps: 'integrations-section-klue-apps',
    klueEmail: 'integrations-section-klue-email',
    menu: (slug: string) => `integrations-section-${slug}-menu`,
    pageSections: {
      all: 'integrations-section-all',
      api: 'integrations-section-api',
      featured: 'integrations-section-featured',
    },
    pat: {
      card: {
        accessTokenCard: 'integrations-pat-card-accessTokenCard',
        revokeButton: 'integrations-pat-card-revokeButton',
      },
      modals: {
        container: 'integrations-pat-modals-container',
        create: {
          button: 'integrations-pat-modals-create-button',
          container: 'integrations-pat-modals-create-container',
          description: 'integrations-pat-modals-create-description',
          expiration: 'integrations-pat-modals-create-expiration',
        },
        list: {
          container: 'integrations-pat-modals-listTokens-container',
          createTokenButton: 'integrations-pat-modals-list-creatTokenButton',
        },
        revoke: {
          button: 'integrations-pat-modals-revoke-button',
          container: 'integrations-pat-modals-revoke-container',
        },
        view: {
          container: 'integrations-pat-modals-view-container',
          copyButton: 'integrations-pat-modals-view-copyButton',
        },
      },
    },
    searchMenu: {
      categories: 'integrations-searchMenu-categories',
      container: 'integrations-searchMenu-container',
      textInput: 'integrations-searchMenu-textInput',
      toggleButton: 'integrations-searchMenu-toggleButton',
    },
  },
  editor: {
    tags: {
      addTag: 'editor-tags-addTag',
    },
    blocks: {
      dynamicBlock: 'editor-blocks-dynamicBlock',
      dynamicBlockView: 'editor-blocks-dynamicBlockView',
      dynamicBlockViewRivalSelect: 'editor-blocks-dynamicBlockView-rivalSelect',
      dynamicBlockViewFormula: 'editor-blocks-dynamicBlockView-formula',
    },
  },
  intelCard: {
    body: 'intel-card-body',
    container: 'intel-card',
    contentHeight: 'intel-card-content-height',
    links: 'intel-card-links',
    floatCardButton: 'intel-card-float-card-button',
    footer: {
      addTagButton: 'intel-card-footer-add-tag-button',
      container: 'intel-card-footer-container',
      recommendedTag: 'intel-card-footer-recommended-tag',
      relatedAlerts: 'intel-card-footer-related-alerts',
      topicsDropdownContainer: 'intel-card-footer-topics-dropdown-container',
      topicsDropdownItem: 'intel-card-footer-topics-dropdown-item',
    },
    fullAlertInFeed: 'intel-card-full-alert-in-feed',
    header: {
      addedToDigestBadge: 'intel-card-header-added-to-digest-badge',
      commentsBadge: 'intel-card-header-comments-badge',
      commentsBadgeCount: 'intel-card-header-comments-badge-count',
      container: 'intel-card-header-container',
      delete: 'intel-card-header-delete',
      divider: 'intel-card-header-divider',
      infoAction: {
        container: 'intel-card-header-info-action-container',
      },
      newPageAlertBadge: 'intel-card-header-new-page-alert-badge',
      outerData: {
        bulkEditCheckbox: 'intel-card-header-outer-data-bulk-edit-checkbox',
        cardIcon: 'intel-card-header-outer-data-card-icon',
        container: 'intel-card-header-outer-data-container',
        relatedBoard: 'intel-card-header-outer-data-related-boards',
      },
      publishedBadge: 'intel-card-header-published-badge',
      toggleMonitor: 'intel-card-header-toggle-monitor',
    },
    headerMetaData: {
      container: 'intel-card-header-metadata-container',
    },
    keepItHereMessage: 'intel-card-keep-it-here-message',
    messageContent: {
      attachmentsList: 'intel-card-message-attachments',
      attachmentsListWithCollapsible:
        'intel-card-message-attachments-with-collapsible',
      container: 'intel-card-message-content',
      replies: 'intel-card-message-replies',
    },
    stateIcon: {
      default: 'intel-card-state-icon-default',
      unprocessed: 'intel-card-state-icon-unprocessed',
      working: 'intel-card-state-icon-working',
      archived: 'intel-card-state-icon-archived',
      deleted: 'intel-card-state-icon-deleted',
    },
    title: {
      cancel: 'intel-card-title-cancel',
      container: 'intel-card-title-container',
      editor: 'intel-card-title-editor',
      revertToOriginal: 'intel-card-title-revert-to-original',
      save: 'intel-card-title-save',
    },
    twitterContent: {
      container: 'intel-card-twitter-content',
    },
  },
  klueCard: {
    cardSelector: {
      checkbox: 'klueCard-cardSelector-checkbox',
      container: 'klueCard-cardSelector',
    },
    container: 'klueCard',
    content: 'klueCard-content',
    dynamicBlock: {
      collapseButton: 'dynamicBlock-collapseButton',
      container: 'dynamicBlock-container',
      errorFallbackText: 'dynamicBlock-errorFallbackText',
      financialTable: {
        header: 'dynamicBlock-financialTable-header',
        row: 'dynamicBlock-financialTable-row',
      },
      jobPostings: {
        container: 'dynamicBlock-jobPostings',
        listItem: 'dynamicBlock-jobPostings-listItem',
      },
      keyPeople: {
        container: 'dynamicBlock-keyPeople',
      },
      keywordsComparisonTable: {
        changeColumn: 'dynamicBlock-keywordsComparisonTable-changeColumn',
        changeHeader: 'dynamicBlock-keywordsComparisonTable-changeHeader',
        row: 'dynamicBlock-keywordsComparisonTable-row',
      },
      officeLocations: {
        listContainer: 'dynamicBlock-officeLocations',
        listItem: 'dynamicBlock-officeLocations-item',
      },
      salesforceLastOpportunities: {
        container: 'dynamicBlock-salesforceLastOpportunities',
      },
      skeleton: 'dynamicBlock-skeleton',
      templateList: {
        templateList: 'dynamicBlock-templateList',
        templateListItem: 'dynamicBlock-templateList-item',
        templateListItemDate: 'dynamicBlock-templateList-itemDate"',
        templateListItemSource: 'dynamicBlock-templateList-itemSource"',
        templateListItemSummary: 'dynamicBlock-templateList-itemSummary',
      },
    },
    header: {
      cardViewer: 'klueCard-header-card-viewer',
      default: 'klueCard-header-default',
      legacy: 'klueCard-header-legacy',
      menu: 'klueCard-header-menu',
      search: 'klueCard-header-search',
    },
    klueCardFeedbackModal: {
      cancel: 'klue-feedback-modal-button-cancel',
      send: 'klue-feedback-modal-button-send',
      success: 'klue-feedback-modal-success',
      textarea: 'klue-feedback-modal-textarea',
    },
    klueCardInfo: {
      boards: 'klueCard-info-boards',
      cardSourceIcon: 'klueCard-info-cardSourceIcon',
      container: 'klueCard-info-container',
    },
    klueCardSentiment: {
      button: {
        negative: 'klueCard-sentiment-button-negative',
        positive: 'klueCard-sentiment-button-positive',
        shareFeedback: 'klueCard-sentiment-button-share-feedback',
      },
      container: 'klueCard-sentiment-container',
    },
    klueCardSources: {
      addSourcesMessageForCurators:
        'klueCard-source-addSourcesMessageForCurators',
      container: 'klueCard-sources',
      header: 'klueCard-sources-header',
      headerViewerContent: 'klueCard-sources-header-viewer-content',
      item: {
        containerViewerContent:
          'klueCard-sources-item-container-viewer-content',
      },
      listContainer: 'klueCard-sources-listContainer',
    },
    klueCardTags: {
      container: 'klueCard-cardTags-container',
    },
    menu: {
      buttons: {
        edit: 'klueCard-menu-buttons-edit',
        openCard: 'klueCard-menu-buttons-openCard',
        sources: 'klueCard-menu-buttons-sources',
      },
    },
    toolbar: {
      container: 'klueCard-toolbar-container',
    },
    transform: {
      image: {
        container: 'klueCard-transform-image-container',
        image: 'klueCard-transform-image-image',
        link: 'klueCard-transform-image-link',
        zoom: 'klueCard-transform-image-zoom',
      },
      imageModal: {
        image: 'klueCard-transform-modal-image',
        link: 'klueCard-transform-modal-link',
      },
    },
    visibilityGroups: {
      container: 'klueCard-visibilityGroups',
      text: 'klueCard-visibilityGroups-text',
      done: 'klueCard-visibilityGroups-done',
    },
  },
  knowledgeHub: {
    variables: {
      container: 'knowledgeHub-variables-container',
      segments: {
        checkbox: 'knowledgeHub-variables-segments-checkbox',
      },
      doneButton: 'knowledgeHub-variables-done-button',
      confirmDialogs: {
        noSegments: {
          container: 'knowledgeHub-variables-confirm-dialog',
          confirmButton: 'knowledgeHub-variables-confirm-button',
          cancelButton: 'knowledgeHub-variables-cancel-button',
        },
        noVariables: {
          container: 'knowledgeHub-variables-confirm-dialog',
          confirmButton: 'knowledgeHub-variables-confirm-button',
          cancelButton: 'knowledgeHub-variables-cancel-button',
        },
      },
      addNewVariable: {
        button: 'knowledgeHub-variables-addNewVariable-button',
        input: 'knowledgeHub-variables-addNewVariable-input',
      },
      removeVariableButton: 'knowledgeHub-variables-removeVariable-button',
    },
    files: {
      container: 'knowledgeHub-files-container',
      list: {
        empty: 'knowledgeHub-files-list-empty',
      },
    },
    positioning: {
      container: 'knowledgeHub-positioning-container',
      addPositioningInput: 'knowledgeHub-positioning-addPositioning-input',
      doneButton: 'knowledgeHub-positioning-done-button',
      empty: {
        link: 'knowledgeHub-positioning-empty-link',
      },
    },
    products: {
      container: 'knowledgeHub-products-container',
      addProductButton: 'knowledgeHub-products-addProduct-button',
      addProductInput: 'knowledgeHub-products-addProduct-input',
      removeProductButton: 'knowledgeHub-products-removeProduct-button',
      itemContainer: 'knowledgeHub-products-item-container',
      doneButton: 'knowledgeHub-products-done-button',
      confirmDialogs: {
        noProducts: {
          container: 'knowledgeHub-variables-confirm-dialog',
          confirmButton: 'knowledgeHub-variables-confirm-button',
          cancelButton: 'knowledgeHub-variables-cancel-button',
        },
      },
    },
    done: {
      link: 'knowledgeHub-done-back-to-dashboard-link',
    },
    widget: {
      button: 'knowledgeHub-widget-button',
    },
    checklist: {
      container: 'knowledgeHub-checklist-container',
    },
  },
  landingPage: {
    container: 'landingPage-container',
  },
  layout: {
    appNavBar: {
      hamburgerMenuButton: 'layout-appNavBar-hamburgerMenuButton',
      userAvatarLink: 'layout-appNavBar-userAvatarLink',
    },
    appSideAction: {
      container: 'layout-appSideAction',
    },
    appSidebar: {
      companyLogo: 'layout-appSidebar-companyLogo',
      container: 'layout-appSidebar',
      logoFallback: 'layout-appSidebar-logoFallback',
    },
    appSideMenu: {
      closeButton: 'layout-appSideMenu-closeButton',
      layer: 'layout-appSideMenu-layer',
    },
    appSideNav: {
      container: 'layout-appSideNav',
    },
    curatorPreviewBanner: {
      container: 'layout-curatorPreviewBanner',
    },
    horizontalScroller: {
      fade: {
        left: 'layout-horizontalScroller-fade-left',
        right: 'layout-horizontalScroller-fade-right',
      },
    },
    mainLayout: {
      container: 'layout-mainLayout',
      content: 'layout-mainLayout-content',
    },
    navBar: {
      container: 'layout-navBar',
    },
    previewBanner: {
      container: 'layout-previewBanner',
      groupSwitcher: 'layout-previewBanner-groupSwitcher',
      resetButton: 'layout-previewBanner-resetButton',
    },
    quickSwitcher: {
      closeButton: 'layout-quickSwitcher-closeButton',
      companyLogo: 'layout-quickSwitcher-companyLogo',
      container: 'layout-quickSwitcher',
      quickFinderInput: 'layout-quickSwitcher-quickFinderInput',
      quickFinderLabel: 'layout-quickSwitcher-quickFinderLabel',
      quickFinderSuggestionItem:
        'layout-quickSwitcher-quickFinderSuggestionItem',
      recentlyViewed: 'layout-quickSwitcher-recentlyViewed',
      recentlyViewedBattlecardButton:
        'layout-quickSwitcher-recentlyViewedBattlecardButton',
      recentlyViewedProfileButton:
        'layout-quickSwitcher-recentlyViewedProfileButton',
      rivalConnectorBattlecardButton:
        'layout-quickSwitcher-rivalConnectorBattlecardButton',
      rivalConnectorProfileButton:
        'layout-quickSwitcher-rivalConnectorProfileButton',
      rivalGroupItem: 'layout-quickSwitcher-rivalGroupItem',
    },
    rivalItem: {
      container: 'layout-rivalItem',
      rivalBattlecardButton: 'layout-rivalItem-rivalBattlecardButton',
      rivalLogo: 'layout-rivalItem-rivalLogo',
      rivalProfileButton: 'layout-rivalItem-rivalProfileButton',
    },
    scroller: {
      backToTop: 'layout-scroller-back-to-top',
      container: 'layout-scroller-container',
      fade: {
        bottom: 'layout-scroller-fade-bottom',
        top: 'layout-scroller-fade-top',
      },
      moreTip: 'layout-scroller-moreTip',
      scroll: 'layout-scroller-scroll',
    },
    sidePane: {
      container: 'layout-side-pane-container',
      content: 'layout-side-pane-content',
      leftIcon: 'layout-side-pane-left-icon',
      rightIcon: 'layout-side-pane-right-icon',
    },
  },
  monitors: {
    container: 'monitors-container',
    emptyContainer: 'monitors-empty-container',
    errorContainer: 'monitors-error-container',
    loadingContainer: 'monitors-loading-container',
    monitorCard: {
      actionMenu: {
        button: 'monitors-monitorCard-actionMenu-button',
      },
      collapseButton: 'monitors-monitorCard-collapse-button',
      container: 'monitors-monitorCard-container',
      diffImage: 'monitors-monitorCard-diffImage',
      diffImageContainer: 'monitors-monitorCard-diffImage-container',
      diffText: 'monitors-monitorCard-diffText',
      expandButton: 'monitors-monitorCard-expand-button',
      expandTitle: 'monitors-monitorCard-expand-title',
      header: {
        container: 'monitors-monitorCard-header-container',
        info: 'monitors-monitorCard-header-info',
        infoContent: 'monitors-monitorCard-header-info-content',
        title: 'monitors-monitorCard-header-title',
      },
      relatedBoard: 'monitors-monitorCard-relatedBoard',
      tabs: {
        container: 'monitors-monitorCard-tabs-container',
        image: 'monitors-monitorCard-tabs-image',
        imageContainer: 'monitors-monitorCard-tabs-image-container',
        loadingContainer: 'monitors-monitorCard-tabs-loading-container',
        text: 'monitors-monitorCard-tabs-text',
        textContainer: 'monitors-monitorCard-tabs-text-container',
      },
      toggleChangesOnly: 'monitors-monitorCard-toggleChangesOnly',
    },
    topBar: {
      container: 'monitors-topBar-container',
      count: 'monitors-topBar-count',
      filters: {
        container: 'monitors-topBar-filters-container',
      },
      settingsButton: 'monitors-topBar-settings-button',
      showAllButton: 'monitors-topBar-showAll-button',
      showLessButton: 'monitors-topBar-showLess-button',
    },
  },
  profile: {
    battlecardsNav: {
      container: 'profile-battlecardsNav-container',
      emptyListMessage: 'profile-battlecardsNav-emptyListMessage',
      listContainer: 'profile-battlecardsNav-listContainer',
      listItem: 'profile-battlecardsNav-listItem',
    },
    boardLane: {
      boardLaneContainer: 'profile-boardLane-container',
      boardLaneSortableContainer: 'profile-boardLane-sortable-container',
      container: 'profile-boardLane',
      content: 'profile-boardLane-content',
      header: 'profile-boardLane-header',
      addCard: 'profile-boardLane-addCard',
    },
    boardNews: {
      container: 'profile-boardNews-container',
    },
    boardView: {
      container: 'profile-boardView-container',
      filteredEmpty: 'profile-boardView-filteredEmpty',
      loading: 'profile-boardView-loading',
    },
    bulkeditSelectAllButton: 'profile-bulkedit-selectAllButton',
    clone: {
      button: 'profile-clone-button',
      confirmationDialog: 'profile-clone-confirmationDialog',
    },
    cardsNav: {},
    cardViewer: {
      cardViewerHeader: {
        button: 'profile-cardViewer-headerButton',
        container: 'profile-cardViewer-header',
      },
      container: 'profile-cardViewer',
      content: 'profile-cardViewer-content',
    },
    commentsNav: {},
    layoutControl: {
      container: 'profile-layoutControl-container',
    },
    listView: {
      board: 'profile-listView-board',
      boardHeader: 'profile-listView-boardHeader',
      card: 'profile-listView-card',
      cardSelector: 'profile-listView-cardSelector',
      cardSkeleton: 'profile-listView-cardSkeleton',
      container: 'profile-listView',
      tagsContainer: 'profile-listView-tagsContainer',
    },
    newsNav: {
      closeBtn: 'profile-newsNav-closeBtn',
      container: 'profile-newsNav-container',
      disclaimer: 'profile-newsNav-disclaimer',
      heading: 'profile-newsNav-heading',
      thumb: 'profile-newsNav-thumb',
      viewNewsFeedBtn: 'profile-newsNav-viewNewsFeedBtn',
    },
    sideNav: {
      breadcrumbs: 'profile-sideNav-breadcrumbs',
      collapseArrow: 'profile-sideNav-collapseArrow',
      container: 'profile-sideNav-container',
      content: 'profile-sideNav-content',
      homeLink: 'profile-sideNav-homeLink',
      item: 'profile-sideNav-item',
    },
    subNav: {
      allCardsButton: 'dashboard-news-all-cards-button',
      battlecardChooser: {
        button: 'battlecard-battleCard-chooser-button',
        container: 'battlecard-battleCard-chooser-container',
      },
      bookmarks: {
        container: 'profile-subNav-filter-bookmark-container',
        saveButton: 'profile-subNav-filter-bookmark-saveButton',
      },
      buttonHighlight: 'profile-subNav-buttonHighlight',
      container: 'profile-subNav-container',
      filters: {
        applied: 'profile-subNav-filtersApplied',
        closeButton: 'profile-subNav-filtersCloseButton',
        drop: 'profile-subNav-filtersDrop',
        dropButton: 'profile-subNav-filtersDropButton',
        lanesTab: 'profile-subNav-filtersLanesTab',
        tagsTab: 'profile-subNav-filtersTagsTab',
      },
      manageBattlecards: 'profile-subNav-manageBattlecards',
      newsButton: 'profile-subNav-newsButton',
      newsButtonBadge: 'profile-subNav-newsButtonBadge',
      profileNameButton: 'profile-subNav-profileNameButton',
      rivalLogo: 'profile-subNav-rivalLogo',
      vitals: {
        dropButton: 'profile-subNav-vitalsDropButton',
      },
      organizeBoard: 'profile-subNav-organizeBoard',
    },
    vitals: {
      container: 'profile-vitalsContainer',
    },
  },
  profileCard: {
    avatar: 'profile-card-avatar',
    battlecards: 'profile-card-battlecards',
    container: 'profile-card-container',
    draftBadge: 'profile-card-draft-badge',
    lastUpdate: 'profile-card-last-update',
    lastUpdateContainer: 'profile-card-last-update-container',
    lastUpdateUserName: 'profile-card-last-update-user-name',
    showMoreButton: 'profile-card-show-more-button',
  },
  relatedBoardsList: {
    addBoardButton: 'related-board-list-add-button',
    container: 'related-boards-list',
    dropContent: 'related-boards-list-drop-content',
    rivalSuggestion: 'related-board-list-rival-suggestion-item',
    suggestionInput: 'related-board-suggestions-input',
    viewInBoardButton: 'related-board-list-view-in-board-button',
  },
  reports: {
    container: 'reports-container',
    iframe: 'reports-iframe',
    individualReports: {
      container: 'reports-individual-report-container',
      filterButton: 'reports-individual-report-filter-button',
    },
    landingPage: {
      container: 'reports-landing-page-container',
      mainInfo: 'reports-landing-page-main-info',
    },
    layout: 'reports-layout',
    nav: {
      button: 'reports-nav-button',
      dropContent: 'reports-nav-dropdown',
      heading: 'reports-nav-heading',
    },
    opportunities: {
      uploader: {
        container: 'reports-opportunities-uploader-container',
      },
    },
    report: {
      approveButton: 'reports-report-approve-button',
      backButton: 'reports-reports-back-button',
      banner: 'reports-report-banner',
      container: 'reports-report-container',
      downloadData: 'reports-reports-download-data',
      filterButton: 'reports-report-filter-button',
      header: 'reports-report-header',
      importData: 'reports-reports-import-data',
      loading: 'reports-report-loading',
      selectorMenu: 'reports-report-selector-menu',
      uploadData: 'reports-reports-upload-data',
    },
    reportSelector: 'reports-report-selector',
    selector: {
      competitiveAnalyticsSingle:
        'reports-selector-competitiveAnalytics-single',
      dropButton: 'reports-selector-dropButton',
      curatorButton: 'reports-selector-curatorButton',
      items: {
        'competitive-analytics': {
          parent: 'reports-selector-competitive-analytics',
          overview: 'reports-selector-competitive-analytics-overview',
          'threat-analysis':
            'reports-selector-competitive-analytics-threat-analysis',
          'rep-performance':
            'reports-selector-competitive-analytics-rep-performance',
          'data-hygiene': 'reports-selector-competitive-analytics-data-hygiene',
          impact: 'reports-selector-competitive-analytics-impact',
          'program-impact':
            'reports-selector-competitive-analytics-programimpact',
        },
        'consumer-usage': {
          parent: 'reports-selector-consumer-usage',
          overview: 'reports-selector-consumer-usage-overview',
          leaderboards: 'reports-selector-consumer-usage-leaderboards',
          individual: 'reports-selector-consumer-usage-individual',
        },
      },
    },
    uploadConfirmationModal: {
      closeButton: 'reports-upload-confirmation-modal-close-button',
      confirmButton: 'reports-upload-confirmation-modal-confirm-button',
      container: 'reports-upload-confirmation-modal-container',
      textInput: 'reports-upload-confirmation-modal-text-input',
    },
  },
  research: {
    note: {
      container: 'research-note-container',
    },
  },
  reviewCard: {
    container: 'review-card-container',
    pinIcon: 'review-card-pin-icon',
  },
  reviewInsights: {
    aspectFilters: {
      allButton: 'review-insights-aspect-filters-all-button',
      allReviewsButton: 'review-insights-aspect-filters-all-reviews-button',
      bookmarkedReviewsButton:
        'review-insights-aspect-filters-bookmarked-reviews-button',
      container: 'review-insights-aspect-filters-container',
      item: 'review-insights-aspect-filters-item',
      itemButton: 'review-insights-aspect-filters-item-button',
      negativeButton: 'review-insights-aspect-filters-negative-button',
      positiveButton: 'review-insights-aspect-filters-positive-button',
      sortFilterButton: 'review-insights-aspect-filters-sort-filter-button',
    },
    aspectSummary: {
      actionMenu: {
        button: 'review-insights-aspect-summary-action-menu-button',
        createCard: 'review-insights-aspect-summary-action-menu-create-card',
      },
      body: {
        container: 'review-insights-aspect-summary-body-container',
        loading: 'review-insights-aspect-summary-body-loading',
        reloadButton: 'review-insights-aspect-summary-body-reload-button',
        settings: 'review-insights-aspect-summary-body-settings',
      },
      container: 'review-insights-aspect-summary-container',
      generateButton: 'review-insights-aspect-summary-generate-button',
    },
    error: 'review-insights-generic-error',
    header: {
      container: 'review-insights-header-container',
      requestCompetitorButton:
        'review-insights-header-request-competitor-button',
    },
    insightsDashboard: {
      container: 'review-insights-insights-dashboard-container',
      insightsCardFeedback: {
        feedbackButton:
          'review-insights-insights-dashboard-insights-card-feedback-button',
        negative:
          'review-insights-insights-dashboard-insights-card-feedback-negative',
        positive:
          'review-insights-insights-dashboard-insights-card-feedback-positive',
      },
      loading: 'review-insights-insights-dashboard-loading',
    },
    loading: 'review-insights-loading',
    reviews: {
      container: 'review-insights-reviews-container',
      emptyMessage: 'review-insights-reviews-empty-message',
    },
    sidebar: {
      accordionButton: 'review-insights-sidebar-accordion-button',
      breadcrumb: {
        button: 'review-insights-sidebar-breadcrumb-button',
      },
      container: 'review-insights-sidebar-container',
      item: 'review-insights-sidebar-item',
      productItem: 'review-insights-sidebar-product-item',
    },
    view: 'review-insights-view',
    createCardModal: {
      container: 'review-insights-create-card-modal-container',
      loading: 'review-insights-create-card-modal-loading',
      error: 'review-insights-create-card-modal-error',
      titleItemButton: 'review-insights-create-card-modal-title-item-button',
    },
  },
  reviewStarRating: {
    container: 'intel-card-review-star-rating-container',
    star: 'intel-card-review-star-rating-star',
  },
  search: {
    cards: {
      breadcrumb: {
        avatarIcon: 'search-cardBreadcrumb-avatar-icon',
        avatarImage: 'search-cardBreadcrumb-avatar-image',
        boardLink: 'search-cardBreadcrumb-boardLink',
        container: 'search-cardBreadcrumb-container',
        profileLink: 'search-cardBreadcrumb-profileLink',
      },
    },
    container: 'search-container',
    empty: {
      perplexity: {
        container: 'search-empty-perplexity-container',
        error: 'search-empty-perplexity-error',
        loading: {
          container: 'search-empty-perplexity-loading-container',
        },
        results: {
          container: 'search-empty-perplexity-results-container',
        },
      },
    },
    results: {
      container: 'search-results-container',
      filterButton: 'search-filter-button',
      header: 'search-results-header',
      info: 'search-results-info',
      loader: 'search-results-loader',
      empty: {
        feedback: 'search-results-empty-feedback',
        feedbackInput: 'search-results-empty-feedbackInput',
        cancel: 'search-results-empty-cancel',
        positiveSentiment: 'search-results-empty-positiveSentiment',
        negativeSentiment: 'search-results-empty-negativeSentiment',
      },
    },
    sidebar: {
      container: 'search-sidebar-container',
      filterWidget: 'search-sidebar-filterWidget',
      inputCheckbox: 'search-sidebar-inputCheckbox',
      inputRadio: 'search-sidebar-inputRadio',
      searchInput: 'search-sidebar-searchInput',
      tagsAccordion: 'search-sidebar-tagsAccordion',
    },
  },
  settings: {
    container: 'settings-container',
    monitorSettings: {
      addMonitorButton: 'settings-monitorSettings-addMonitorButton',
      addMonitorModal: {
        cancel: 'settings-monitorSettings-addMonitorModal-cancel',
        confirm: 'settings-monitorSettings-addMonitorModal-confirm',
      },
      container: 'settings-monitorSettings-container',
      deleteMonitorButton: 'settings-monitorSettings-deleteMonitorButton',
      deleteMonitorDialog: 'settings-monitorSettings-deleteMonitorDialog',
      monitorSearch: 'settings-monitorSettings-monitorSearch',
      monitorsEmptyList: 'settings-monitorSettings-monitorsEmptyList',
      monitorSetting: 'settings-monitorSettings-monitorSetting',
      monitorsList: 'settings-monitorSettings-monitorsList',
      monitorsListItem: 'settings-monitorSettings-monitorsListItem',
      monitorsListRival: 'settings-monitorSettings-monitorsListRival',
      unassignedSetting: 'settings-monitorSettings-unassignedSetting',
    },
    sideMenu: 'settings-sideMenu',
  },
  sideNav: {
    collapseArrow: 'sideNav-collapseArrow',
    container: 'sideNav-container',
    content: 'sideNav-content',
    homeLink: 'sideNav-homeLink',
    item: 'sideNav-item',
  },
  submitIntel: {
    header: 'submit-intel-header',
    select: 'submit-intel-select',
    submitButton: 'submit-intel-submit-button',
    textArea: 'submit-intel-text-area',
  },
  summary: {
    buttons: {
      applyButton: 'summary-buttons-apply-button',
      cancelButton: 'summary-buttons-cancel-button',
      closeButton: 'summary-buttons-close-button',
      container: 'summary-buttons-container',
      saveButton: 'summary-buttons-save-button',
    },
    container: 'summary-container',
    editor: 'summary-editor',
    error: {
      container: 'summary-error-container',
    },
    input: 'summary-input',
    settings: {
      container: 'summary-settings-container',
      setDefault: 'summary-settings-default-settings-check',
    },
    spinner: 'summary-spinner',
    summarization: {
      delete: 'commandCenter-summarization-delete-button',
      edit: 'commandCenter-summarization-edit-button',
      feedback: 'commandCenter-summarization-feedback-button',
      menu: 'commandCenter-summarization-menu-button',
      retry: 'commandCenter-summarization-retry-button',
      tryAgain: 'commandCenter-summarization-try-again-button',
    },
    summarizeButton: 'summary-summarize-button',
    text: 'summary-readonly-text',
  },
  supportedRivalCard: {
    avatar: 'supportedRival-card-avatar',
    container: 'supportedRival-card-container',
  },
  userAvatar: {
    anchor: {
      email: 'userAvatar-anchor-email',
      profile: 'userAvatar-anchor-profile',
    },
    avatar: 'userAvatar-avatar',
    box: 'userAvatar-box',
    name: 'userAvatar-name',
  },
  whyItMatters: {
    buttons: {
      cancelButton: 'why-it-matters-buttons-cancel-button',
      container: 'why-it-matters-buttons-container',
      saveButton: 'why-it-matters-buttons-save-button',
    },
    container: 'why-it-matters-container',
    editControl: {
      confirmDeleteButton: 'why-it-matters-editControl-confirm-delete-button',
      deleteButton: 'why-it-matters-editControl-delete-button',
      editButton: 'why-it-matters-editControl-edit-button',
    },
    header: {
      container: 'why-it-matters-header-container',
      date: 'why-it-matters-header-date',
    },
    input: 'why-it-matters-input',
    text: 'why-it-matters-readonly-text',
  },
  winLoss: {
    chartSkeleton: 'chart-skeleton',
    createCardModal: 'win-loss-create-card-modal',
    createProgramModal: 'win-loss-create-program-modal',
    filterBar: {
      dropdownButton: 'filterBar-dropdown-button',
    },
    highlightItem: 'highlight-item',
    highlightsTitle: 'highlights-title',
    iframe: 'win-loss-iframe',
    interviewDetailsButton: 'interview-details-button',
    interviewItem: 'interview-item',
    interviewsTitle: 'interviews-title',
    opportunitiesHeaders: 'opportunities-headers',
    opportunitiesItem: 'opportunities-item',
    opportunitiesTitle: 'opportunities-title',
    overviewDescription: 'overview-description',
    overviewTitle: 'overview-title',
    programCard: 'program-card',
    programDetailsDescription: 'programs-details-description',
    programDetailsThemesTitle: 'programs-details-themes-title',
    programDetailsTitle: 'programs-details-title',
    programsTitle: 'programs-title',
    reportItem: 'report-item',
    reportsTitle: 'reports-title',
    surveyItem: 'survey-item',
    surveyTitle: 'survey-title',
  },
};

export default TEST_IDS;

/* eslint-disable max-lines */
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchCompany } from 'api/endpoints/company';
import { fetchUserMe, updateCurrentUser } from 'api/endpoints/users';
import { fetchVisibilityGroupById } from 'api/endpoints/visibility-groups';
import { useTimeoutSessionManager } from 'hooks/use-timeout-session-manager';

import {
  checkAdminAccess,
  checkCuratorAccess,
  checkReportsAccess,
  checkTagVisibility,
  checkCardInfoVisibility,
  checkImpersonating,
  checkCardViewerUpdateAccess,
  checkCompanyLinkDefault,
  checkIsAlertsAutoSummarizeOnTriageEnabled,
  checkCardSentimentAccess,
  checkDashboardNewsAccess,
  checkCardFeedbackDisabled,
  checkCardSentimentAndFeedbackOnHoverDisabled,
  checkIsAlertsRecommendedTagEnabled,
  checkIsWinLossEnabled,
  checkIsWinLossExclusive,
  checkIsBattlecardLayoutLabelDisabled,
  checkCompetitiveAnalyticsOverviewReportEnabled,
  checkCompetitiveAnalyticsThreatAnalysisReportEnabled,
  checkCompetitiveAnalyticsRepPerformanceReportEnabled,
  checkCompetitiveAnalyticsDataHygieneReportEnabled,
  checkCompetitiveAnalyticsImpactReportEnabled,
  checkIsConsumerReportEnabledForCurators,
  checkIsCuratorReportEnabledForCurators,
  checkIsCRAReportEnabledForCurators,
  checkisCompetitiveDashboardEnabledForConsumers,
  checkIsAlertsNotificationEnabledForCurators,
  checkIsRivalLinkHiddenForConsumers,
  checkAskKlueEnabled,
  checkGAEnabled,
  checkIsReviewInsightsCreateCardEnabled,
  checkSFDCDataSyncContactsEnabled,
  checkResearchAccess,
  checkComposerAccess,
  checkProfileSideNavNewsDisabled,
  checkResearchChatAccess,
  checkResearchDevAccess,
  checkAlertsTitleEditingDisabled,
  checkIsCompetitiveAnalyticsProgramImpactReportEnabled,
  checkisNewCuratorNavEnabled,
  checkIsFeedHiddenInConsumerNavEnabled,
  checkIsInsightsWhyWeWinLoseEnabled,
  checkIsCallInsightsDemoReplacementEnabled,
  checkDashboardNewsToggleDefault,
  checkIfNewCardEditorEnabled,
  checkIfCardTitlesOptional,
  checkIsNewCardRendererEnabled,
  checkIsSFDCDataSyncUserRoleEnabled,
  checkIsSFDSOnTemporalEnabled,
  checkIsSearchSuggestionBattlecardsEnabled,
  checkIsGenAISearchResultsEnabled,
  checkIsV2CuratorDashboardEnabled,
  checkCompanyLandingPageEnabled,
  checkSupportedRivalSearchResultsEnabled,
  checkCompanyFavoritesEnabled,
  checkIsSFDSCustomLookupObjectEnabled,
  checkIsHSDSIntegaritonEnabled,
  checkIsAskKlueWizardEnabled,
  checkIsAskKlueSingleClickPromptEnabled,
  checkIsConsumerAskKlueToggleHidden,
  checkIsDashboardAskKluePreviewForCuratorsEnabled,
  checkIsDashboardAskKlueEnabled,
  checkKnowledgeHubEnabled,
  checkIsBattlecardAskKlueEnabled,
  checkIsUnreadStateEnabledForConsumers,
  checkUpdateSummaryEnabled,
  checkIsAskKlueExportEnabled,
} from './auth.utils';

import type {
  OptionalUserType,
  OptionalCompanyType,
  CompanyLinkDefaultType,
} from './auth.types';
import type { Dispatch, RootState } from 'store/store.types';

type VisibilityGroupType = number | null;

export default function useAuth() {
  const [loading, setLoading] = useState(true);
  const {
    auth: {
      setCompany,
      setUser,
      setVisibilityGroup,
      signOut: dispatchSignOut,
      revertImpersonating: dispatchRevertImpersonating,
    },
    visibilityGroups: { populate: populateVisibilityGroups },
  } = useDispatch<Dispatch>();

  const company = useSelector<RootState, OptionalCompanyType>(
    (state) => state.auth.company,
  );
  const user = useSelector<RootState, OptionalUserType>(
    (state) => state.auth.user,
  );
  const visibilityGroup = useSelector<RootState, VisibilityGroupType>(
    (state) => state.auth.visibilityGroup,
  );

  const updateUser = async (
    updateData: Partial<NonNullable<OptionalUserType>>,
  ) => {
    const { data } = await updateCurrentUser({
      params: updateData,
    });

    setUser(data);

    return data;
  };

  const signOut = async () => {
    await dispatchSignOut({});
    setUser(null);
    setLoading(false);
  };

  const revertImpersonatingUser = async () => {
    await dispatchRevertImpersonating({});
  };

  const { startTimeoutSessionManager } = useTimeoutSessionManager({
    onSignOut: signOut,
  });

  const fetchUser = useCallback(async () => {
    try {
      const user = await fetchUserMe();
      const company = await fetchCompany();

      if (isEmpty(user.data) || isEmpty(company.data)) {
        throw Error('auth data incomplete');
      }

      setUser(user.data);
      setCompany(company.data);

      const isCurator = checkCuratorAccess(user.data);

      const previewingParam = new URL(
        document.location.toString(),
      ).searchParams.get('previewing');

      if (isCurator && previewingParam) {
        if (previewingParam !== '0') {
          const { data: visibilityGroup } = await fetchVisibilityGroupById({
            path: { id: +previewingParam },
          });
          if (visibilityGroup.id) {
            populateVisibilityGroups({ visibilityGroups: [visibilityGroup] });
            setVisibilityGroup(visibilityGroup.id);
          }
        } else {
          setVisibilityGroup(0);
        }
      }

      startTimeoutSessionManager();
    } catch (error: any) {
      setUser(null);
      setCompany(null);
      throw error?.data?.error || 'Authentication failed';
    } finally {
      setLoading(false);
    }
  }, [
    setUser,
    setCompany,
    startTimeoutSessionManager,
    populateVisibilityGroups,
    setVisibilityGroup,
  ]);

  const isAdmin = checkAdminAccess(user, visibilityGroup);
  const isCurator = checkCuratorAccess(user, visibilityGroup);
  const isStaff = user?.isStaff || false;

  const isReportsEnabled = checkReportsAccess(company, user, visibilityGroup);
  const isDigestAvailable =
    isAdmin ||
    !company?.reviewRole ||
    !!user?.roles.includes(company?.reviewRole as string);

  const isTagVisible = checkTagVisibility(company, user, visibilityGroup);
  const showCardInfo = checkCardInfoVisibility(company);

  const isCardViewerUpdateEnabled = checkCardViewerUpdateAccess(company);
  const isDashboardNewsEnabled = checkDashboardNewsAccess(company);
  const isCardFeedbackDisabled = checkCardFeedbackDisabled(company);
  const isCardSentimentEnabled = checkCardSentimentAccess(company);
  const isCardSentimentAndFeedbackOnHoverDisabled =
    checkCardSentimentAndFeedbackOnHoverDisabled(company);
  const isAlertsAutoSummarizeOnTriageEnabled =
    checkIsAlertsAutoSummarizeOnTriageEnabled(company);
  const isImpersonating = checkImpersonating(user);

  const isBattlecardLayoutLabelDisabled =
    checkIsBattlecardLayoutLabelDisabled(company);
  const isAlertsRecommendedTagEnabled =
    checkIsAlertsRecommendedTagEnabled(company);
  const isWinLossEnabled = checkIsWinLossEnabled(
    company,
    user,
    visibilityGroup,
  );

  const isWinLossExclusive = checkIsWinLossExclusive(
    company,
    user,
    visibilityGroup,
  );

  const isCompetitiveAnalyticsOverviewReportEnabled =
    checkCompetitiveAnalyticsOverviewReportEnabled(user, company);
  const isCompetitiveAnalyticsThreatAnalysisReportEnabled =
    checkCompetitiveAnalyticsThreatAnalysisReportEnabled(user, company);
  const isCompetitiveAnalyticsRepPerformanceReportEnabled =
    checkCompetitiveAnalyticsRepPerformanceReportEnabled(user, company);
  const isCompetitiveAnalyticsDataHygieneReportEnabled =
    checkCompetitiveAnalyticsDataHygieneReportEnabled(user, company);
  const isCompetitiveAnalyticsImpactReportEnabled =
    checkCompetitiveAnalyticsImpactReportEnabled(user, company);
  const isCompetitiveAnalyticsProgramImpactReportEnabled =
    checkIsCompetitiveAnalyticsProgramImpactReportEnabled(user, company);
  const isConsumerReportEnabledForCurators =
    checkIsConsumerReportEnabledForCurators(company);
  const isCuratorReportEnabledForCurators =
    checkIsCuratorReportEnabledForCurators(company);
  const isCRAReportEnabledForCurators =
    checkIsCRAReportEnabledForCurators(company);
  const isCompetitiveDashboardEnabledForConsumers =
    checkisCompetitiveDashboardEnabledForConsumers(company);
  const isAskKlueExportEnabled = checkIsAskKlueExportEnabled(company);
  const isRivalLinkHiddenForConsumers = checkIsRivalLinkHiddenForConsumers(
    company,
    user,
    visibilityGroup,
  );
  const isAlertsNotificationEnabledForCurators =
    checkIsAlertsNotificationEnabledForCurators(company, user, visibilityGroup);
  const isUnreadStateEnabledForConsumers =
    checkIsUnreadStateEnabledForConsumers(company, user, visibilityGroup);

  const isSFDCDataSyncContactsEnabled = checkSFDCDataSyncContactsEnabled(
    company,
    user,
    visibilityGroup,
  );

  const isAskKlueEnabled = checkAskKlueEnabled(company, user);
  const isDashboardAskKlueEnabled = checkIsDashboardAskKlueEnabled(company);
  const isDashboardAskKluePreviewForCuratorsEnabled =
    checkIsDashboardAskKluePreviewForCuratorsEnabled(company, user);

  const isDashboardNewsToggleDefaultOff =
    checkDashboardNewsToggleDefault(company);

  const isCompanyLandingPageEnabled = checkCompanyLandingPageEnabled(company);
  const isSupportedRivalSearchResultsEnabled =
    checkSupportedRivalSearchResultsEnabled(company);
  const isCompanyFavoritesEnabled = checkCompanyFavoritesEnabled(
    company,
    user,
    visibilityGroup,
  );
  const companyLinkDefault: CompanyLinkDefaultType = checkCompanyLinkDefault(
    company,
    user,
    visibilityGroup,
  );
  const isUpdateSummaryEnabled = checkUpdateSummaryEnabled(company);

  const isSFDCDataSyncUserRoleEnabled =
    checkIsSFDCDataSyncUserRoleEnabled(company);

  const isSFDSCustomLookupObjectEnabled =
    checkIsSFDSCustomLookupObjectEnabled(company);

  const isSFDSOnTemporalEnabled = checkIsSFDSOnTemporalEnabled(company);

  const isHSDSEnabled = checkIsHSDSIntegaritonEnabled(company);

  const isGenAISearchResultsEnabled = checkIsGenAISearchResultsEnabled(company);

  const isSearchSuggestionBattlecardsEnabled =
    checkIsSearchSuggestionBattlecardsEnabled(company);

  const isGAEnabled = checkGAEnabled(company);

  const isReviewInsightsCreateCardEnabled =
    checkIsReviewInsightsCreateCardEnabled(company);

  const homeRoute = isWinLossExclusive
    ? '/win-loss'
    : company?.companyData?.forceHome || '/dashboard';

  const isProfileSideNavNewsDisabled = checkProfileSideNavNewsDisabled(company);
  const isResearchEnabled = checkResearchAccess(company, user, visibilityGroup);
  const isComposerEnabled = checkComposerAccess(company, user, visibilityGroup);
  const isResearchChatEnabled = checkResearchChatAccess(company);
  const isResearchDevEnabled = checkResearchDevAccess(company);
  const isAlertsTitleEditingDisabled = checkAlertsTitleEditingDisabled(company);
  const isFeedHiddenInConsumerNavEnabled =
    checkIsFeedHiddenInConsumerNavEnabled(company);

  const isNewCuratorNavEnabled = checkisNewCuratorNavEnabled(company);

  const isInsightsWhyWeWinLoseEnabled =
    checkIsInsightsWhyWeWinLoseEnabled(company);

  const isCallInsightsDemoReplacementEnabled =
    checkIsCallInsightsDemoReplacementEnabled(company);

  const isNewCardEditorEnabled = checkIfNewCardEditorEnabled(
    company,
    user,
    visibilityGroup,
  );

  const isNewCardRendererEnabled = checkIsNewCardRendererEnabled(company);

  const isCardTitleOptional = checkIfCardTitlesOptional(company);

  const isV2CuratorDashboardEnabled = checkIsV2CuratorDashboardEnabled(company);

  const isAskKlueWizardEnabled = checkIsAskKlueWizardEnabled(company);

  const isAskKlueSingleClickPromptEnabled =
    checkIsAskKlueSingleClickPromptEnabled(company);

  const isConsumerAskKlueToggleHidden =
    checkIsConsumerAskKlueToggleHidden(company);

  const isKnowledgeHubEnabled = checkKnowledgeHubEnabled(
    company,
    user,
    visibilityGroup,
  );

  const isCompetitiveDashboardVisible = isNil(
    user?.userData?.isCompetitiveDealWidgetVisible,
  )
    ? user?.userData?.isSeller && isCompetitiveDashboardEnabledForConsumers
    : user?.userData?.isSeller &&
      user?.userData?.isCompetitiveDealWidgetVisible &&
      isCompetitiveDashboardEnabledForConsumers;

  const isBattlecardAskKlueEnabled = checkIsBattlecardAskKlueEnabled(company);

  return {
    user,
    company,
    visibilityGroup,
    updateUser,
    companyLinkDefault,
    loading,
    signOut,
    showCardInfo,
    homeRoute,
    isAdmin,
    isCurator,
    isStaff,
    isDigestAvailable,
    isReportsEnabled,
    isTagVisible,
    isCardViewerUpdateEnabled,
    isDashboardNewsEnabled,
    isCardFeedbackDisabled,
    isComposerEnabled,
    isCardSentimentEnabled,
    isCardSentimentAndFeedbackOnHoverDisabled,
    isAlertsAutoSummarizeOnTriageEnabled,
    isAlertsRecommendedTagEnabled,
    fetchUser,
    isImpersonating,
    revertImpersonatingUser,
    isWinLossEnabled,
    isBattlecardLayoutLabelDisabled,
    isWinLossExclusive,
    isCompetitiveAnalyticsOverviewReportEnabled,
    isCompetitiveAnalyticsThreatAnalysisReportEnabled,
    isCompetitiveAnalyticsRepPerformanceReportEnabled,
    isCompetitiveAnalyticsDataHygieneReportEnabled,
    isCompetitiveAnalyticsImpactReportEnabled,
    isCompetitiveAnalyticsProgramImpactReportEnabled,
    isConsumerReportEnabledForCurators,
    isCuratorReportEnabledForCurators,
    isCRAReportEnabledForCurators,
    isAskKlueExportEnabled,
    isCompetitiveDashboardEnabledForConsumers,
    isAlertsNotificationEnabledForCurators,
    isRivalLinkHiddenForConsumers,
    isUnreadStateEnabledForConsumers,
    isAskKlueEnabled,
    isDashboardAskKlueEnabled,
    isBattlecardAskKlueEnabled,
    isDashboardAskKluePreviewForCuratorsEnabled,
    isDashboardNewsToggleDefaultOff,
    isCompanyLandingPageEnabled,
    isSupportedRivalSearchResultsEnabled,
    isCompanyFavoritesEnabled,
    isUpdateSummaryEnabled,
    isSFDCDataSyncUserRoleEnabled,
    isSFDSOnTemporalEnabled,
    isSFDSCustomLookupObjectEnabled,
    isHSDSEnabled,
    isGAEnabled,
    isSFDCDataSyncContactsEnabled,
    isReviewInsightsCreateCardEnabled,
    isProfileSideNavNewsDisabled,
    isResearchEnabled,
    isResearchChatEnabled,
    isResearchDevEnabled,
    isAlertsTitleEditingDisabled,
    isNewCuratorNavEnabled,
    isFeedHiddenInConsumerNavEnabled,
    isInsightsWhyWeWinLoseEnabled,
    isCallInsightsDemoReplacementEnabled,
    isNewCardEditorEnabled,
    isNewCardRendererEnabled,
    isCardTitleOptional,
    isV2CuratorDashboardEnabled,
    isSearchSuggestionBattlecardsEnabled,
    isGenAISearchResultsEnabled,
    isAskKlueWizardEnabled,
    isAskKlueSingleClickPromptEnabled,
    isConsumerAskKlueToggleHidden,
    isKnowledgeHubEnabled,
    isCompetitiveDashboardVisible,
  };
}
